import React, { useEffect, useState } from "react";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

import { useDispatch, useSelector } from "react-redux";

import Joyride, { EVENTS, STATUS } from "react-joyride";
import { useWindowSize } from "../../Commons/utils";
import { updateUserTourSetupData } from "../../../../store/service/AuthService";

const stepData = [
  {
    title: (
      <TextStyles.FontSize18px>Set up your account</TextStyles.FontSize18px>
    ),
    target: ".setup_your_user_info_tour",
    disableBeacon: true,
  },
  {
    title: (
      <TextStyles.FontSize18px>Set up your currency</TextStyles.FontSize18px>
    ),
    target: ".setup_your_currency_tour",
  },
  {
    title: <TextStyles.FontSize18px>Set up your brand</TextStyles.FontSize18px>,
    target: ".setup_your_brand_logo_tour",
  },
  {
    title: (
      <TextStyles.FontSize18px>Set up your business</TextStyles.FontSize18px>
    ),
    target: ".setup_your_business_info_tour",
  },

  {
    title: (
      <TextStyles.FontSize18px>Create your first task</TextStyles.FontSize18px>
    ),
    target: ".create_dashboard_task_tour",
    content: (
      <TextStyles.FontSize14px className="d-flex text-start">
        After creating a task, you can see all your created tasks in the task
        list.
      </TextStyles.FontSize14px>
    ),
  },
  {
    title: (
      <TextStyles.FontSize18px>
        Create your first client
      </TextStyles.FontSize18px>
    ),
    target: ".clients_tour",
    content: (
      <TextStyles.FontSize14px className="d-flex text-start">
        After creating a client, you can manage their projects, invoices, time
        tracking, and other useful features.
      </TextStyles.FontSize14px>
    ),
    placement: "right-start",
  },
];

function firstrow() {
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const firstName = useSelector(
    (state) => state.auth.data && state.auth.data.firstName
  );
  const steps = width > 992 ? stepData : stepData.slice(0, stepData.length - 1);
  const tourData = useSelector((state) => state.auth.userTourSetupData);
  const [run, setRun] = useState(true);

  useEffect(() => {
    setRun(tourData && tourData.dashboard_tour);
  }, [tourData]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
    if ([EVENTS.TOUR_END, EVENTS.SKIP].includes(type)) {
      dispatch(updateUserTourSetupData({ dashboard_tour: false }));
    }
  };

  return (
    <div className="mx-lg-4 mx-md-4 mx-sm-3 mx-2 pt-4">
      <TextStyles.FontSize14px className="d-flex justify-content-lg-start justify-content-xl-start justify-content-md-start justify-content-center fs-1">
        <span>Hello {firstName}!</span>
      </TextStyles.FontSize14px>
      <Joyride
        steps={steps}
        run={run}
        disableBeacon
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        scrollToFirstStep={false}
        callback={handleJoyrideCallback}
        scrollOffset={200}
        locale={{ skip: "Skip All" }}
        styles={{
          options: {
            primaryColor: Colors.primary,
            textColor: Colors.font1,
            zIndex: 50,
            overlayColor: "rgba(0, 0, 0, 0.2)",
            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0)",
            width: 300,
          },
          beacon: {
            zIndex: 50,
          },
          overlay: {
            zIndex: 50,
          },
          tooltipTitle: {
            fontSize: 18,
            margin: 0,
          },
          tooltipContent: {
            padding: "10px 0 0 0",
          },
        }}
        defaultStyles={{
          tooltipContent: {
            padding: "0px",
          },
        }}
      />
    </div>
  );
}

export default firstrow;
