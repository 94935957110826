import React, { useEffect, useMemo, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";

import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import EventIcon from "@mui/icons-material/Event";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NoTimeTrackingImage from "../../../../Assets/assetsnew/no-time-tracking.svg";

import { Popover } from "antd";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

import mergeTimer from "../../../../Assets/assetsnew/merge-timer-symbol.svg";

import Colors from "../../../../Constants/Colors";
import Styled from "./TimerStyles";
import TextStyles from "../../../../Constants/TextStyles";
import { useDispatch, useSelector } from "react-redux";

import { message } from "antd";
import {
  start_timer_activity,
  fetchAllTimerActivities,
  update_multiple_timer_activity_names,
  update_single_timer_activity_name,
  update_multiple_timer_project_names,
  update_single_timer_project_name,
  delete_multiple_timer_activities,
  delete_single_timer_activity,
  update_multiple_timer_dates,
  update_single_timer_date,
  update_multiple_timer_invoices,
  update_single_timer_invoice,
  merge_timer_activities,
  fetchAllActivityLists,
  createActivityList,
} from "../../../../store/service/timeTrackingService";

import { convertSecondsToHHMMSS } from "../../Commons/utils";

import {
  AutoCompleteSelectProject,
  AutoCompleteCreateActivity,
} from "../../Commons/TimeTrackingAutoComplete/Autocomplete";

import {
  AutoCompleteSelectProjectList,
  AutoCompleteCreateActivityList,
} from "../../Commons/TimeTrackingAutoComplete/Autocompletelist";

import moment from "moment";
import DeleteModal from "../../Commons/DeleteModal";

import SingleActivityEditModal from "../TimerModals/SingleActivityEditModal";
import SingleActivityAddModal from "../TimerModals/SingleActivityAddModal";

import SelectProjects from "../../Commons/SelectProjects";
import SelectClients from "../../Commons/SelectClients";
import SelectActivity from "../../Commons/SelectActivity";

import TTInvoicedStatus from "../../Commons/TTInvoicedStatus";
import Pagination from "../../Commons/Pagination";
import { ReactTooltip } from "../../Commons/ReactTooltip";
import { timeTrackingActions } from "../../../../store/storage/timetrackingSlice";
import SelectDate from "../../Commons/SelectDate";
import LoaderSpin from "../../Commons/LoaderSpin";
import CustomDatePickerThree from "../../Commons/DatePickers/CustomDatePickerThree";
import CustomTimePickerTwo from "../../Commons/DatePickers/CustomTimePickerTwo";

const TimerList = () => {
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState(null);
  const [updateRefresh, setUpdateRefresh] = useState(false);
  const ttData = useSelector((state) => state.timeTracking.ttData);
  const refresh = useSelector((state) => state.timeTracking.refresh);
  const activityListData = useSelector(
    (state) => state.timeTracking.activityListData
  );
  const toggleFilter = useSelector((state) => state.timeTracking.toggleFilter);

  const filteredData = useSelector((state) => state.timeTracking.filteredData);

  const filteredProject =
    filteredData && filteredData.filteredProject
      ? filteredData.filteredProject
      : null;

  const filteredClient =
    filteredData && filteredData.filteredClient
      ? filteredData.filteredClient
      : null;
  const filteredActivity =
    filteredData && filteredData.filteredActivity
      ? filteredData.filteredActivity
      : null;
  const filteredInvoiced =
    filteredData && filteredData.filteredInvoiced
      ? filteredData.filteredInvoiced
      : null;
  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;

  const sortingData = useSelector((state) => state.timeTracking.sortingData);

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "start_time";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  const totalTtCount = useSelector((state) => state.timeTracking.totalTtCount);
  const isLoading = useSelector((state) => state.timeTracking.isLoading);
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const defaultTTData = useMemo(() => {
    const filteredData =
      ttData &&
      ttData.map((item) => {
        const start_time =
          item.sameDateProjectActivityArray &&
          item.sameDateProjectActivityArray[0] &&
          item.sameDateProjectActivityArray[0].start_time
            ? new Date(item.sameDateProjectActivityArray[0].start_time)
            : null;

        const activity_name =
          item.sameDateProjectActivityArray &&
          item.sameDateProjectActivityArray[0] &&
          item.sameDateProjectActivityArray[0].activity_name &&
          activityListData &&
          activityListData
            .filter(
              (d) =>
                d.activity_name ===
                item.sameDateProjectActivityArray[0].activity_name
            )
            .map((data) => {
              return {
                _id: data._id,
                title: data.activity_name,
              };
            })[0];

        const project_id =
          item.sameDateProjectActivityArray &&
          item.sameDateProjectActivityArray[0] &&
          item.sameDateProjectActivityArray[0].project_id
            ? {
                _id: item.sameDateProjectActivityArray[0].project_id._id,
                title:
                  item.sameDateProjectActivityArray[0].project_id.project_name,
                client_id:
                  item.sameDateProjectActivityArray[0].client_id &&
                  item.sameDateProjectActivityArray[0].client_id._id,
                client_name:
                  item.sameDateProjectActivityArray[0].client_id &&
                  item.sameDateProjectActivityArray[0].client_id.company_name,
              }
            : null;
        const is_invoiced =
          item.sameDateProjectActivityArray &&
          item.sameDateProjectActivityArray.some(
            (item) => item.is_invoiced === true
          );
        const sameDateProjectActivityArray =
          item.sameDateProjectActivityArray &&
          item.sameDateProjectActivityArray.map((element) => {
            const _id = element._id;
            const start_time =
              element.start_time && new Date(element.start_time);
            const timesInSecs = element.timesInSecs;
            const activity_name =
              element.activity_name &&
              activityListData &&
              activityListData
                .filter((d) => d.activity_name === element.activity_name)
                .map((data) => {
                  return {
                    _id: data._id,
                    title: data.activity_name,
                  };
                })[0];

            const is_invoiced = element.is_invoiced;
            const project_id = element.project_id
              ? {
                  _id: element.project_id && element.project_id._id,
                  title: element.project_id && element.project_id.project_name,
                  client_id: element.client_id && element.client_id._id,
                  client_name:
                    element.client_id && element.client_id.company_name,
                }
              : null;
            const timer_status = element.timer_status;
            return {
              _id,
              start_time,
              start_date: start_time,
              timesInSecs,
              activity_name,
              is_invoiced,
              project_id,
              timer_status,
            };
          });
        return {
          start_time,
          start_date: start_time,
          activity_name,
          project_id,
          is_invoiced,
          sameDateProjectActivityArray,
        };
      });
    return filteredData;
  }, [ttData]);

  const { control, reset } = useForm({
    defaultValues: {
      update_activity: defaultTTData,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "update_activity",
  });

  const [multipleConfirmDialog, setMultipleConfirmDialog] = useState(false);
  const [multipleDeleteModal, setMultipleDeleteModal] = useState(false);
  const handleCloseMultipleDeleteModal = () => setMultipleDeleteModal(false);

  const [singleConfirmDialog, setSingleConfirmDialog] = useState(false);
  const [singleDeleteModal, setSingleDeleteModal] = useState(false);
  const handleCloseSingleDeleteModal = () => setSingleDeleteModal(false);

  const [multipleActivitySelectedData, setMultipleActivitySelectedData] =
    useState(null);

  const [singleActivitySelectedData, setSingleActivitySelectedData] =
    useState(null);
  const [singleActivityEditModal, setSingleActivityEditModal] = useState(false);
  const handleCloseSingleActivityEditModal = () =>
    setSingleActivityEditModal(false);

  const [singleActivityAddModal, setSingleActivityAddModal] = useState(false);
  const handleCloseSingleActivityAddModal = () =>
    setSingleActivityAddModal(false);

  const toggleShowFilter = () => {
    dispatch(timeTrackingActions.toggleTimerAction());
  };

  // sorting clients
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      timeTrackingActions.sortingTimerAction({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      timeTrackingActions.sortingTimerAction({
        selectedSortingTwo: event.target.value,
      })
    );
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setPage(1);
  }, [filteredData, itemsPerPage]);

  useEffect(() => {
    if (defaultTTData && defaultTTData.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [defaultTTData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).format("YYYY-MM-DD");
      end_time = moment(filterdDateRange[0]).format("YYYY-MM-DD");
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(6, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(14, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(29, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(44, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(59, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(89, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "This month") {
        const startDateOfThisMonth = moment(new Date())
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDateOfThisMonth = moment(new Date())
          .endOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last month") {
        const endDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        const startDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "This year") {
        const endDateOfThisYear = moment(new Date())
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfThisYear = moment(new Date())
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisYear).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last year") {
        const endDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastYear).format("YYYY-MM-DD");
      }
    }

    const Obj = {
      page: page,
      perPage: itemsPerPage,
      start_time,
      end_time,
      client_id: filteredClient !== 0 ? filteredClient : null,
      project_id: filteredProject !== 0 ? filteredProject : null,
      activity_name: filteredActivity !== 0 ? filteredActivity : null,
      is_invoiced:
        filteredInvoiced === "Invoiced"
          ? true
          : filteredInvoiced === "Not invoiced"
          ? false
          : null,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    dispatch(fetchAllTimerActivities(Obj));
  }, [filteredData, page, itemsPerPage, refresh, updateRefresh, sortingData]);

  const createActivityHandler = (data) => {
    if (data && !data._id) {
      let Obj = {
        activity_name: data.inputValue || data,
      };
      dispatch(timeTrackingActions.addNewActivityList(Obj));
      dispatch(createActivityList(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists());
        }
      });
    }
  };

  useEffect(() => {
    reset({
      update_activity: defaultTTData,
    });
  }, [ttData]);

  const collapsableHandleChange = (index) => {
    if (collapse === index) {
      return setCollapse(null);
    }
    setCollapse(index);
  };

  const restartActivity = (item) => {
    const Obj = {
      project_id: item && item.project_id ? item.project_id._id : null,
      client_id: item && item.project_id ? item.project_id.client_id : null,
      activity_name:
        item && item.activity_name && item.activity_name
          ? item.activity_name.title
          : null,
    };
    dispatch(timeTrackingActions.startTimer(Obj));
    dispatch(start_timer_activity(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const updateMultipleActivityNames = (value, item) => {
    const Obj = {
      activity_name:
        value && value.inputValue
          ? value.inputValue
          : value && value.title
          ? value.title
          : value
          ? value
          : null,
      updateSelectedActivities: item.sameDateProjectActivityArray.map(
        (d) => d._id
      ),
    };

    dispatch(update_multiple_timer_activity_names(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const updateSingleActivityName = (value, element) => {
    const Obj = {
      activity_name:
        value && value.inputValue
          ? value.inputValue
          : value && value.title
          ? value.title
          : value
          ? value
          : null,
      _id: element._id,
    };

    dispatch(update_single_timer_activity_name(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const updateMultipleProjectNames = (value, item) => {
    const Obj = {
      project_id: value && value._id,
      client_id: value && value.client_id,
      updateSelectedActivities: item.sameDateProjectActivityArray.map(
        (d) => d._id
      ),
    };
    dispatch(update_multiple_timer_project_names(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const updateSingleProjectName = (value, element) => {
    const Obj = {
      project_id: value && value._id,
      client_id: value && value.client_id,
      _id: element._id,
    };
    dispatch(update_single_timer_project_name(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const updateMultipleActivityDates = (newDate, item) => {
    const Obj = {
      updateSelectedActivities: item.sameDateProjectActivityArray.map((d) => {
        const _id = d._id;
        const start_time = new Date(
          moment(d.start_time).set({
            year: moment(newDate).get("year"),
            month: moment(newDate).get("month"),
            date: moment(newDate).get("date"),
          })
        );
        return {
          _id,
          start_time,
        };
      }),
    };

    dispatch(update_multiple_timer_dates(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const updateSingleActivityDate = (newDate, element) => {
    const startDate = element && element.start_date;
    const start_time = new Date(
      moment(newDate).set({
        hour: moment(startDate).get("hour"),
        minute: moment(startDate).get("minute"),
        second: moment(startDate).get("second"),
      })
    );
    const Obj = {
      _id: element._id,
      start_time,
    };
    dispatch(update_single_timer_date(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const updateMultipleActivityTimes = (newDate, item) => {
    const Obj = {
      updateSelectedActivities: item.sameDateProjectActivityArray.map((d) => {
        const _id = d._id;
        const start_time = new Date(newDate);
        return {
          _id,
          start_time,
        };
      }),
    };

    dispatch(update_multiple_timer_dates(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const updateSingleActivityTime = (newDate, element) => {
    const start_time = new Date(newDate);
    const Obj = {
      _id: element._id,
      start_time,
    };
    dispatch(update_single_timer_date(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const multipleActivityInvoicedHandler = (item) => {
    const Obj = {
      updateSelectedActivities: item.sameDateProjectActivityArray.map(
        (d) => d._id
      ),
      is_invoiced:
        item &&
        item.sameDateProjectActivityArray &&
        item.sameDateProjectActivityArray[0] &&
        !item.sameDateProjectActivityArray[0].is_invoiced,
    };
    dispatch(update_multiple_timer_invoices(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const singleActivityInvoicedHandler = (element) => {
    const Obj = {
      _id: element._id,
      is_invoiced: !element.is_invoiced,
    };
    dispatch(update_single_timer_invoice(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const deleteMultipleActivitiesHandler = (item) => {
    const Obj = {
      deleteSelectedActivities: item.sameDateProjectActivityArray.map(
        (d) => d._id
      ),
    };

    dispatch(delete_multiple_timer_activities(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseMultipleDeleteModal();

        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const deleteSingleActivityHandler = (element) => {
    const Obj = {
      _id: element._id,
    };
    dispatch(delete_single_timer_activity(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseSingleDeleteModal();
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const mergeActivityHandler = (item) => {
    const mergeActivityList =
      item &&
      item.sameDateProjectActivityArray &&
      item.sameDateProjectActivityArray.map((d) => d._id);
    const project_id = item && item.project_id ? item.project_id._id : null;
    const client_id =
      item && item.project_id ? item.project_id.client_id : null;
    const activity_name =
      item && item.activity_name ? item.activity_name.title : null;
    const is_invoiced = item && item.is_invoiced;
    const start_time =
      item &&
      item.sameDateProjectActivityArray &&
      item.sameDateProjectActivityArray[0] &&
      new Date(item.sameDateProjectActivityArray[0].start_time);

    let totalMergeTime = 0;
    item &&
      item.sameDateProjectActivityArray &&
      item.sameDateProjectActivityArray.forEach((d) => {
        totalMergeTime += d.timesInSecs;
      });

    const Obj = {
      mergeActivityList,
      client_id,
      project_id,
      activity_name,
      start_time,
      timesInSecs: totalMergeTime,
      is_invoiced,
    };
    dispatch(merge_timer_activities(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="company_name">
            <FolderSharedTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Client name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="company_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_name">
            <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Project name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_name"}
              value="project_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="start_time">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="start_time"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "start_time"}
              value="start_time"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="activity_name">
            <AccessTimeIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Activity name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="activity_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "activity_name"}
              value="activity_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center mb-3">
        <TextStyles.FontSize20px className="d-flex justify-content-start">
          Timer
        </TextStyles.FontSize20px>
        <div className="d-flex justify-content-end align-items-center ms-auto">
          <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
            Filter
          </TextStyles.HoverEffectSpan>

          <TextStyles.GreyFont20px className="mx-2">|</TextStyles.GreyFont20px>
          <TextStyles.FontSize16px className="me-2">
            <Popover
              placement="bottomRight"
              // title={"Hello"}
              content={sortingContent}
              trigger="click"
              open={openSorting}
              onOpenChange={handleOpenSortingChange}
            >
              <TextStyles.HoverEffectSpan sort={true}>
                Sort
                <ArrowDropDownOutlinedIcon
                  sx={{
                    color: Colors.font2,
                    fontSize: 27,
                    transform: openSorting ? "rotate(180deg)" : "",
                  }}
                />
              </TextStyles.HoverEffectSpan>
            </Popover>
          </TextStyles.FontSize16px>
          <Styled.Oval
            className="d-flex align-items-center justify-content-center"
            onClick={() => setSingleActivityAddModal(true)}
          >
            <AddOutlinedIcon sx={{ fontSize: 22, color: Colors.white }} />
          </Styled.Oval>
        </div>
      </div>

      {toggleFilter && (
        <div className="d-flex flex-wrap gap-3 justify-content-start mb-3">
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>

            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                dispatch(
                  timeTrackingActions.filterTimerAction({
                    filterdDateRange: value,
                  })
                );
              }}
            />
          </div>
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Project
            </TextStyles.FontSize14px>
            <SelectProjects
              defaultValue={filteredProject && filteredProject}
              options={projectOptionsData}
              onChange={(e) =>
                dispatch(
                  timeTrackingActions.filterTimerAction({
                    filteredProject: e.target.value,
                  })
                )
              }
            />
          </div>
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Client
            </TextStyles.FontSize14px>
            <SelectClients
              defaultValue={filteredClient && filteredClient}
              options={clientOptionData}
              onChange={(e) =>
                dispatch(
                  timeTrackingActions.filterTimerAction({
                    filteredClient: e.target.value,
                  })
                )
              }
            />
          </div>
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Activity
            </TextStyles.FontSize14px>
            <SelectActivity
              defaultValue={filteredActivity && filteredActivity}
              options={activityListData}
              onChange={(e) =>
                dispatch(
                  timeTrackingActions.filterTimerAction({
                    filteredActivity: e.target.value,
                  })
                )
              }
            />
          </div>
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Invoice status
            </TextStyles.FontSize14px>
            <TTInvoicedStatus
              defaultValue={filteredInvoiced && filteredInvoiced}
              onChange={(e) =>
                dispatch(
                  timeTrackingActions.filterTimerAction({
                    filteredInvoiced: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
      )}

      {fields && fields.length > 0 ? (
        fields.map((item, index) => {
          let sum = 0;
          item.sameDateProjectActivityArray &&
            item.sameDateProjectActivityArray.forEach((element) => {
              sum += element.timesInSecs;
            });

          return (
            <Styled.TimerRectangle key={`${item.id}`}>
              <div className="d-flex gap-3 align-items-center w-100">
                <div
                  className="d-flex align-items-center w-100"
                  style={{ maxWidth: 30 }}
                >
                  {item.sameDateProjectActivityArray.length > 1 ? (
                    <TextStyles.FontSize14px
                      className="position-relative"
                      onClick={() => collapsableHandleChange(index)}
                    >
                      <KeyboardArrowUpOutlinedIcon
                        className={
                          collapse === index
                            ? "timer_arrow_up_after_expand"
                            : "timer_arrow_up"
                        }
                        sx={{
                          color: Colors.font2,
                          cursor: "pointer",
                        }}
                      />

                      <TextStyles.FontSize14px
                        style={{
                          width: 30,
                          height: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 3,
                          border: "1px solid #cccccc",
                        }}
                      >
                        {item.sameDateProjectActivityArray &&
                          item.sameDateProjectActivityArray.length}
                      </TextStyles.FontSize14px>

                      <ExpandMoreOutlinedIcon
                        className={
                          collapse === index
                            ? "timer_arrow_down_after_expand"
                            : "timer_arrow_down"
                        }
                        sx={{
                          color: Colors.font2,
                          cursor: "pointer",
                        }}
                      />
                    </TextStyles.FontSize14px>
                  ) : (
                    <PlayCircleFilledWhiteIcon
                      onClick={() => {
                        if (!isLoading) {
                          restartActivity(item);
                        }
                      }}
                      sx={{
                        fontSize: 30,
                        cursor: "pointer",
                        color: Colors.primary,
                      }}
                    />
                  )}
                </div>
                <TextStyles.FontSize14px
                  className="d-flex align-items-center justify-content-center w-100"
                  style={{ maxWidth: 75, minWidth: 20 }}
                >
                  <span className="three-dots">
                    {convertSecondsToHHMMSS(sum).formattedTime}
                  </span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-none d-md-flex align-items-center justify-content-center w-100"
                  style={{ maxWidth: 70, minWidth: 20 }}
                >
                  {item.sameDateProjectActivityArray.length > 1 ? (
                    <div
                      onClick={() => collapsableHandleChange(index)}
                      style={{
                        cursor: "pointer",
                        opacity: isLoading ? 0.4 : 1,
                      }}
                    >
                      {moment(item.start_time).format("LT")}
                    </div>
                  ) : (
                    <Controller
                      name={`update_activity.${index}.start_time`}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CustomTimePickerTwo
                            onChange={(value) => {
                              onChange(value);
                              updateMultipleActivityTimes(value, item);
                            }}
                            selected={value}
                            placeholder="Start time"
                            disabled={isLoading ? true : false}
                          />
                        );
                      }}
                    />
                  )}
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-none d-md-flex align-items-center justify-content-center w-100"
                  style={{
                    maxWidth: 105,
                    minWidth: 20,
                  }}
                >
                  <Controller
                    control={control}
                    name={`update_activity.${index}.start_date`}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomDatePickerThree
                          onChange={(value) => {
                            onChange(value);
                            updateMultipleActivityDates(value, item);
                          }}
                          selected={value}
                          placeholder="Start date"
                          disabled={isLoading ? true : false}
                        />
                      );
                    }}
                  />
                </TextStyles.FontSize14px>

                <div
                  className="d-flex  align-items-center justify-content-start actvity_list_max_width w-100"
                  style={{ minWidth: 100 }}
                >
                  <Controller
                    control={control}
                    name={`update_activity.${index}.activity_name`}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteCreateActivity
                        placeholder="Write activity..."
                        options={
                          activityListData &&
                          activityListData.map((d) => {
                            return { _id: d._id, title: d.activity_name };
                          })
                        }
                        onChange={(value) => {
                          onChange(value);
                          createActivityHandler(value);
                          updateMultipleActivityNames(value, item);
                        }}
                        defaultValue={value}
                        disabled={isLoading ? true : false}
                      />
                    )}
                  />
                </div>
                <div className="d-none d-md-flex gap-3 align-items-center w-100">
                  <Controller
                    control={control}
                    name={`update_activity.${index}.project_id`}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteSelectProject
                        placeholder="Assign to project"
                        options={
                          projectOptionsData &&
                          projectOptionsData.map((d) => {
                            return {
                              _id: d._id ? d._id : null,
                              title:
                                d && d.project_name ? d.project_name : null,
                              client_id:
                                d && d.client_id && d.client_id._id
                                  ? d.client_id._id
                                  : null,
                              client_name:
                                d && d.client_id && d.client_id.company_name
                                  ? d.client_id.company_name
                                  : null,
                            };
                          })
                        }
                        onChange={(value) => {
                          onChange(value);
                          updateMultipleProjectNames(value, item);
                        }}
                        defaultValue={value}
                        disabled={isLoading ? true : false}
                      />
                    )}
                  />
                </div>

                <div
                  className="d-flex"
                  style={{
                    width: "100%",
                    maxWidth: 30,
                    minWidth: 30,
                  }}
                >
                  <FiberManualRecordIcon
                    id={`edit_invoices${index}`}
                    sx={{
                      fontSize: 15,
                      cursor: "pointer",
                      color:
                        item.is_invoiced === true ? Colors.primary : "#dfdfe2",
                      background: Colors.white,
                      padding: "2px 6px",
                      borderRadius: "6px",
                      width: 30,
                      height: 30,
                    }}
                    onClick={() => {
                      if (
                        !isLoading &&
                        item.sameDateProjectActivityArray.length > 1
                      ) {
                        collapsableHandleChange(index);
                      }
                      if (
                        !isLoading &&
                        item.sameDateProjectActivityArray.length === 1
                      ) {
                        multipleActivityInvoicedHandler(item);
                      }
                    }}
                  />
                  <ReactTooltip
                    anchorId={`edit_invoices${index}`}
                    content={item.is_invoiced ? "Invoiced" : "Not invoiced"}
                  />
                </div>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-end"
                  style={{
                    width: "100%",
                    maxWidth: 30,
                    minWidth: 30,
                  }}
                >
                  <div className="dropdown">
                    <div data-bs-toggle="dropdown" aria-expanded="false">
                      <MoreHorizIcon
                        fontSize="large"
                        sx={{
                          background: Colors.white,
                          cursor: "pointer",
                          borderRadius: "6px",
                          padding: "2px 4px",
                          height: "30px",
                          color: "grey",
                        }}
                      />
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {item.sameDateProjectActivityArray.length === 1 && (
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => {
                              setSingleActivityEditModal(true);
                              setSingleActivitySelectedData(
                                item.sameDateProjectActivityArray[0]
                              );
                            }}
                          >
                            <TextStyles.FontSize14px>
                              Edit
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}

                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => {
                            setMultipleDeleteModal(true);
                            setMultipleActivitySelectedData(
                              item.sameDateProjectActivityArray
                            );
                            setMultipleConfirmDialog({
                              onConfirm: () =>
                                deleteMultipleActivitiesHandler(item),
                            });
                          }}
                        >
                          <TextStyles.FontSize14px>
                            Delete
                          </TextStyles.FontSize14px>
                        </button>
                      </li>
                    </ul>
                  </div>
                </TextStyles.FontSize14px>
              </div>

              {collapse === index && (
                <div>
                  {item.sameDateProjectActivityArray.length > 1 && (
                    <>
                      <div
                        style={{
                          width: "1px",
                          height: "40px",
                          marginLeft: "15px",
                          marginTop: -12,
                          opacity: "0.2",
                          border: "solid 0.5px #979797",
                        }}
                      />
                      <TextStyles.FontSize16px className="d-flex">
                        <button
                          onClick={() => {
                            if (!isLoading) {
                              restartActivity(item);
                            }
                          }}
                        >
                          <PlayCircleFilledWhiteIcon
                            sx={{
                              fontSize: 30,
                              cursor: "pointer",
                              color: Colors.primary,
                            }}
                          />
                        </button>
                        <div className="ms-3">Start timer</div>
                      </TextStyles.FontSize16px>
                      <div
                        style={{
                          width: "1px",
                          height: "40px",
                          margin: "0px 0px 0px 15px",
                          opacity: "0.2",
                          border: "solid 0.5px #979797",
                        }}
                      />
                    </>
                  )}

                  {item.sameDateProjectActivityArray &&
                    item.sameDateProjectActivityArray.length > 1 &&
                    item.sameDateProjectActivityArray.map((element, i) => {
                      return (
                        <div key={`${element._id}`} style={{ marginTop: -10 }}>
                          <div className="d-flex gap-3 align-items-center w-100">
                            <div
                              className="d-flex align-items-center w-100"
                              style={{ maxWidth: 30 }}
                            >
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  margin: "0px 9px 0px 11px",
                                  borderRadius: "50%",
                                  backgroundColor: Colors.font2,
                                }}
                              />
                            </div>
                            <TextStyles.FontSize14px
                              className="d-flex align-items-center justify-content-center w-100"
                              style={{ maxWidth: 75, minWidth: 20 }}
                            >
                              <span className="three-dots">
                                {
                                  convertSecondsToHHMMSS(element.timesInSecs)
                                    .formattedTime
                                }
                              </span>
                            </TextStyles.FontSize14px>
                            <TextStyles.FontSize14px
                              className="d-none d-md-flex align-items-center justify-content-center w-100"
                              style={{ maxWidth: 70, minWidth: 20 }}
                            >
                              <Controller
                                control={control}
                                name={`update_activity.${index}.sameDateProjectActivityArray.${i}.start_time`}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <CustomTimePickerTwo
                                      onChange={(value) => {
                                        onChange(value);
                                        updateSingleActivityTime(
                                          value,
                                          element
                                        );
                                      }}
                                      selected={value}
                                      placeholder="Start time"
                                      disabled={isLoading ? true : false}
                                    />
                                  );
                                }}
                              />
                            </TextStyles.FontSize14px>
                            <TextStyles.FontSize14px
                              className="d-none d-md-flex align-items-center justify-content-center w-100"
                              style={{ maxWidth: 105, minWidth: 20 }}
                            >
                              <Controller
                                control={control}
                                name={`update_activity.${index}.sameDateProjectActivityArray.${i}.start_date`}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <CustomDatePickerThree
                                      onChange={(value) => {
                                        onChange(value);
                                        updateSingleActivityDate(
                                          value,
                                          element
                                        );
                                      }}
                                      selected={value}
                                      placeholder="Start date"
                                      disabled={isLoading ? true : false}
                                    />
                                  );
                                }}
                              />
                            </TextStyles.FontSize14px>
                            <div
                              className="d-flex align-items-center justify-content-start actvity_list_max_width w-100"
                              style={{ minWidth: 100 }}
                            >
                              <Controller
                                control={control}
                                name={`update_activity.${index}.sameDateProjectActivityArray.${i}.activity_name`}
                                render={({ field: { onChange, value } }) => (
                                  <AutoCompleteCreateActivityList
                                    placeholder="Write activity..."
                                    options={
                                      activityListData &&
                                      activityListData.map((d) => {
                                        return {
                                          _id: d._id,
                                          title: d.activity_name,
                                        };
                                      })
                                    }
                                    onChange={(value) => {
                                      onChange(value);
                                      createActivityHandler(value);
                                      updateSingleActivityName(value, element);
                                    }}
                                    defaultValue={value}
                                  />
                                )}
                              />
                            </div>

                            <div className="d-none d-md-flex gap-3 align-items-center w-100">
                              <Controller
                                control={control}
                                name={`update_activity.${index}.sameDateProjectActivityArray.${i}.project_id`}
                                render={({ field: { onChange, value } }) => (
                                  <AutoCompleteSelectProjectList
                                    placeholder="Assign to project"
                                    options={
                                      projectOptionsData &&
                                      projectOptionsData.map((d) => {
                                        return {
                                          _id: d._id ? d._id : null,
                                          title:
                                            d && d.project_name
                                              ? d.project_name
                                              : null,
                                          client_id:
                                            d && d.client_id && d.client_id._id
                                              ? d.client_id._id
                                              : null,
                                          client_name:
                                            d &&
                                            d.client_id &&
                                            d.client_id.company_name
                                              ? d.client_id.company_name
                                              : null,
                                        };
                                      })
                                    }
                                    onChange={(value) => {
                                      onChange(value);
                                      updateSingleProjectName(value, element);
                                    }}
                                    defaultValue={value}
                                  />
                                )}
                              />
                            </div>

                            <div
                              className="d-flex"
                              style={{
                                width: "100%",
                                maxWidth: 30,
                                minWidth: 30,
                              }}
                            >
                              <FiberManualRecordIcon
                                id={`edit_invoice${i}`}
                                sx={{
                                  fontSize: 15,
                                  cursor: "pointer",
                                  color:
                                    element.is_invoiced === true
                                      ? Colors.font1
                                      : "#dfdfe2",
                                  background: Colors.white,
                                  padding: "2px 6px",
                                  borderRadius: "6px",
                                  width: 30,
                                  height: 30,
                                }}
                                onClick={() => {
                                  singleActivityInvoicedHandler(element);
                                }}
                              />
                              <ReactTooltip
                                anchorId={`edit_invoice${i}`}
                                content={
                                  element.is_invoiced
                                    ? "Invoiced"
                                    : "Not invoiced"
                                }
                              />
                            </div>

                            <TextStyles.FontSize14px
                              className="d-flex justify-content-end"
                              style={{
                                width: "100%",
                                maxWidth: 30,
                                minWidth: 30,
                              }}
                            >
                              <div className="dropdown">
                                <div
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <MoreHorizIcon
                                    fontSize="large"
                                    sx={{
                                      background: Colors.white,
                                      cursor: "pointer",
                                      borderRadius: "6px",
                                      padding: "2px 4px",
                                      height: "30px",
                                      color: "grey",
                                    }}
                                  />
                                </div>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        setSingleActivityEditModal(true);
                                        setSingleActivitySelectedData(element);
                                      }}
                                    >
                                      <TextStyles.FontSize14px>
                                        Edit
                                      </TextStyles.FontSize14px>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        setSingleDeleteModal(true);
                                        setSingleConfirmDialog({
                                          onConfirm: () =>
                                            deleteSingleActivityHandler(
                                              element
                                            ),
                                        });
                                      }}
                                    >
                                      <TextStyles.FontSize14px>
                                        Delete
                                      </TextStyles.FontSize14px>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </TextStyles.FontSize14px>
                          </div>
                          <div
                            style={{
                              width: "1px",
                              height: "40px",
                              margin: "-15px 0px 0px 15px",
                              opacity: "0.2",
                              border: "solid 0.5px #979797",
                            }}
                          />
                        </div>
                      );
                    })}

                  {item.sameDateProjectActivityArray.length > 1 && (
                    <TextStyles.FontSize14px
                      className="d-flex justify-content-start ms-1"
                      onClick={() => {
                        if (!isLoading) {
                          mergeActivityHandler(item);
                        }
                      }}
                      style={{
                        maxWidth: 150,
                        cursor: "pointer",
                        marginTop: -12,
                      }}
                    >
                      <img src={mergeTimer} alt="mergeBtn" />
                      <div className="ms-2">Merge activities</div>
                    </TextStyles.FontSize14px>
                  )}
                </div>
              )}
            </Styled.TimerRectangle>
          );
        })
      ) : isLoading && (ttData === null || ttData.length === 0) ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        !isLoading &&
        (ttData === null || ttData.length === 0) && (
          <Styled.ListBox>
            <img src={NoTimeTrackingImage} alt="no-time-tracting-img" />
            <TextStyles.GreyFont18px className="mt-2">
              There is no activity yet.
            </TextStyles.GreyFont18px>
          </Styled.ListBox>
        )
      )}

      {totalTtCount > itemsPerPage && (
        <div className="d-flex justify-content-center py-4">
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(totalTtCount / itemsPerPage)}
            page={page}
          />
        </div>
      )}

      {multipleDeleteModal && (
        <DeleteModal
          title={
            multipleActivitySelectedData.length > 1 ? "activities" : "actvity"
          }
          confirmDialog={multipleConfirmDialog}
          showDeleteModal={multipleDeleteModal}
          handleCloseDeleteModal={handleCloseMultipleDeleteModal}
        />
      )}

      {singleDeleteModal && (
        <DeleteModal
          title="activity"
          confirmDialog={singleConfirmDialog}
          showDeleteModal={singleDeleteModal}
          handleCloseDeleteModal={handleCloseSingleDeleteModal}
        />
      )}

      {singleActivityEditModal && (
        <SingleActivityEditModal
          setUpdateRefresh={setUpdateRefresh}
          updateRefresh={updateRefresh}
          show={singleActivityEditModal}
          handleClose={handleCloseSingleActivityEditModal}
          selectedData={singleActivitySelectedData}
        />
      )}

      {singleActivityAddModal && (
        <SingleActivityAddModal
          setUpdateRefresh={setUpdateRefresh}
          updateRefresh={updateRefresh}
          show={singleActivityAddModal}
          handleClose={handleCloseSingleActivityAddModal}
        />
      )}
    </div>
  );
};

export default React.memo(TimerList);
