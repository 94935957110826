import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NoTransactionsImage from "../../../../../Assets/assetsnew/no-transaction.svg";

import { Popover } from "antd";

import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import EventIcon from "@mui/icons-material/Event";
import DescriptionIcon from "@mui/icons-material/Description";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import PaidTwoToneIcon from "@mui/icons-material/PaidTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import NorthSharpIcon from "@mui/icons-material/NorthSharp";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "../../../Transaction/TranscationStyles";
import Colors from "../../../../../Constants/Colors";

import {
  deleteTransaction,
  fetchTransactionsForGraph,
} from "../../../../../store/service/transactionService";

import DeleteModal from "../../../Commons/DeleteModal";
import Pagination from "../../../Commons/Pagination";
import SelectProjects from "../../../Commons/SelectProjects";
import SelectTransactionStatus from "../../../Commons/SelectTransaction";
import NewExpense from "../../../Transaction/TrModals/NewExpense";
import EditExpense from "../../../Transaction/TrModals/EditExpense";
import { ReactTooltip } from "../../../Commons/ReactTooltip";
import TransactionCSV from "../../../Transaction/TransactionCSV";
import SelectDate from "../../../Commons/SelectDate";
import LoaderSpin from "../../../Commons/LoaderSpin";
import { postRequest } from "../../../../../axios/axios";
import URL from "../../../../../axios/constant";
import { trAction } from "../../../../../store/storage/transactionSlice";

export default function Transaction() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isTrLoading, setIsTrLoading] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [trTotalCount, setTrTotalCount] = useState(null);

  const error = useSelector((state) => state.transaction.error);
  const trDataForGraph = useSelector(
    (state) => state.transaction.trDataForGraph
  );
  const c_data_position = useSelector((state) => state.clients.c_data_position);
  const userCurrency = useSelector((state) => state.auth.userCurrency);
  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );
  const toggleFilter = useSelector(
    (state) => state.transaction.toggleFilterInClient
  );

  const filteredData = useSelector(
    (state) => state.transaction.filteredDataInClient
  );

  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;
  const filteredProject =
    filteredData && filteredData.filteredProject
      ? filteredData.filteredProject
      : null;

  const filteredTransactionStatus =
    filteredData && filteredData.filteredTransactionStatus
      ? filteredData.filteredTransactionStatus
      : null;

  const sortingData = useSelector(
    (state) => state.transaction.sortingDataInClient
  );

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "paid_date";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  // for delete modal
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  // New Expense modal
  const [showNewExpenseModal, setShowNewExpenseModal] = useState(false);
  const handleCloseNewExpenseModal = () => setShowNewExpenseModal(false);

  // EditExpense modal
  const [showEditExpenseModal, setShowEditExpenseModal] = useState(false);
  const [selectedExpenseData, setSelectedExpenseData] = useState(null);
  const handleCloseEditExpenseModal = () => setShowEditExpenseModal(false);

  // filtered state
  const [updateRefresh, setUpdateRefresh] = useState(false);

  const toggleShowFilter = () => {
    dispatch(trAction.toggleActionInClient());
  };

  // sorting clients
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      trAction.sortingActionInClient({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      trAction.sortingActionInClient({
        selectedSortingTwo: event.target.value,
      })
    );
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setPage(1);
  }, [
    filteredData,
    itemsPerPage,
    // updateRefresh,
  ]);

  useEffect(() => {
    if (transaction && transaction.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [transaction]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  const fetchTrHandler = async (data) => {
    const token = localStorage.getItem("token");
    setIsTrLoading(true);
    postRequest(URL.FTECH_TRANSACTIONS.url, token, data)
      .then((data) => {
        if (data.data.success) {
          setIsTrLoading(false);
          setTransaction(data.data.data.data);
          setTrTotalCount(data.data.data.trTotalCount);
        }
      })
      .catch((err) => {
        setIsTrLoading(false);
        if (err && err.response && err.response.data) {
          return message.error(`${err.response.data.message}`);
        }
        if (err.isAxiosError) {
          return message.error(`${err.message}`);
        }
      });
  };

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).format("YYYY-MM-DD");
      end_time = moment(filterdDateRange[0]).format("YYYY-MM-DD");
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(6, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(14, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(29, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(44, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(59, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(89, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "This month") {
        const startDateOfThisMonth = moment(new Date())
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDateOfThisMonth = moment(new Date())
          .endOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last month") {
        const endDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        const startDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "This year") {
        const endDateOfThisYear = moment(new Date())
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfThisYear = moment(new Date())
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisYear).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last year") {
        const endDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastYear).format("YYYY-MM-DD");
      }
    }

    const Obj = {
      start_time,
      end_time,
      client_id: c_data_position && c_data_position._id,
      project_id: filteredProject !== 0 ? filteredProject : null,
      transaction_type:
        filteredTransactionStatus !== "All Transactions"
          ? filteredTransactionStatus
          : null,
    };
    fetchTrHandler({
      ...Obj,
      page: page,
      perPage: itemsPerPage,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    });
    // dispatch(fetchTransactions(Obj));
    dispatch(fetchTransactionsForGraph(Obj));
  }, [filteredData, page, itemsPerPage, updateRefresh, sortingData]);

  const deleteTransactionHandler = (data) => {
    dispatch(deleteTransaction({ transaction_id: data._id })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${error}!`);
      }
    });
  };

  const viewIncomeRelatedInvoiceHandler = (data) => {
    history.push(`/db/invoices/view/${data.invoice_id}/details`);
  };

  const currencySymbol = useMemo(() => {
    return (
      userCurrency && userCurrency.currency && userCurrency.currency.symbol
    );
  }, [userCurrency]);

  const transactionIncomesArray = useMemo(() => {
    return (
      trDataForGraph &&
      trDataForGraph
        .filter((d) => d.transaction_type === "income")
        .map((item) => item.total_amount_in_user_currency)
    );
  }, [trDataForGraph]);

  const totalIncomes = useMemo(() => {
    return (
      transactionIncomesArray &&
      transactionIncomesArray.reduce(
        (preValue, curValue) => parseFloat(preValue) + parseFloat(curValue),
        0
      )
    );
  }, [transactionIncomesArray]);

  const transactionExpensesArray = useMemo(() => {
    return (
      trDataForGraph &&
      trDataForGraph
        .filter((d) => d.transaction_type === "expense")
        .map((item) => item.total_amount_in_user_currency)
    );
  }, [trDataForGraph]);

  const totalExpenses = useMemo(() => {
    return (
      transactionExpensesArray &&
      transactionExpensesArray.reduce(
        (preValue, curValue) => parseFloat(preValue) + parseFloat(curValue),
        0
      )
    );
  }, [transactionExpensesArray]);

  const total = useMemo(() => {
    return totalIncomes - totalExpenses;
  }, [totalIncomes, totalExpenses]);

  const mappedDataForCsv = useMemo(() => {
    const returnData =
      trDataForGraph &&
      trDataForGraph.map((data) => {
        let discription = data.purpose;
        let client = data.client_id && data.client_id.company_name;
        let project = data.project_id && data.project_id.project_name;
        let date = moment(new Date(data.paid_date)).format("DD/MM/YYYY");
        let transactionType = data.transaction_type;
        let amount = `${currencySymbol}${
          data.total_amount_in_user_currency
            ? parseFloat(data.total_amount_in_user_currency).toFixed(2)
            : "00.00"
        }`;
        return {
          Description: discription,
          Date: date,
          Client: client,
          Project: project,
          Transaction_Type: transactionType,
          Amount: amount,
        };
      });
    return returnData;
  }, [trDataForGraph]);

  const csvData = [
    ...mappedDataForCsv,
    // Row with sum
    {},
    {
      Transaction_Type: "Total income",
      Amount: `${currencySymbol}${parseFloat(totalIncomes).toFixed(2)}`,
    },
    {
      Transaction_Type: "Total expenses",
      Amount: `${currencySymbol}${parseFloat(totalExpenses).toFixed(2)}`,
    },
    {
      Transaction_Type: "Profit before taxes",
      Amount: `${total < 0 ? "-" : ""}${currencySymbol}${parseFloat(
        Math.abs(total)
      ).toFixed(2)}`,
    },
  ];

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="company_name">
            <FolderSharedTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Client name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="company_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_name">
            <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Project name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_name"}
              value="project_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="purpose">
            <DescriptionIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Description</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="purpose"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "purpose"}
              value="purpose"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="paid_date">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Paid date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="paid_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "paid_date"}
              value="paid_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label
            className="form-check-label"
            htmlFor="total_amount_in_user_currency"
          >
            <PaidTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Invoiced amount</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="total_amount_in_user_currency"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "total_amount_in_user_currency"}
              value="total_amount_in_user_currency"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  const setSortingActiveHandler = (selectedSort) => {
    dispatch(
      trAction.sortingActionInClient({
        selectedSortingOne: selectedSort,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center my-3">
        <Styled.Boxes onClick={() => setShowNewExpenseModal(true)}>
          <AddOutlinedIcon sx={{ fontSize: 22, color: Colors.white }} />
          <span className="me-2">New expense</span>
        </Styled.Boxes>
        <div className="d-flex justify-content-end align-items-center ms-auto">
          <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
            Filter
          </TextStyles.HoverEffectSpan>
          <TextStyles.GreyFont20px className="mx-2">|</TextStyles.GreyFont20px>
          <TextStyles.FontSize16px className="me-2">
            <Popover
              placement="bottomRight"
              // title={"Hello"}
              content={sortingContent}
              trigger="click"
              open={openSorting}
              onOpenChange={handleOpenSortingChange}
            >
              <TextStyles.HoverEffectSpan sort={true}>
                Sort
                <ArrowDropDownOutlinedIcon
                  sx={{
                    color: Colors.font2,
                    fontSize: 27,
                    transform: openSorting ? "rotate(180deg)" : "",
                  }}
                />
              </TextStyles.HoverEffectSpan>
            </Popover>
          </TextStyles.FontSize16px>
          <TransactionCSV data={csvData} />
        </div>
      </div>
      {toggleFilter && (
        <div className="d-flex flex-wrap justify-content-start mb-3">
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>

            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                dispatch(
                  trAction.filterActionInClient({
                    filterdDateRange: value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Project
            </TextStyles.FontSize14px>
            <SelectProjects
              defaultValue={filteredProject && filteredProject}
              options={projectOptionsData}
              onChange={(e) =>
                dispatch(
                  trAction.filterActionInClient({
                    filteredProject: e.target.value,
                  })
                )
              }
            />
          </div>

          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Status
            </TextStyles.FontSize14px>
            <SelectTransactionStatus
              defaultValue={
                filteredTransactionStatus && filteredTransactionStatus
              }
              onChange={(e) =>
                dispatch(
                  trAction.filterActionInClient({
                    filteredTransactionStatus: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
      )}

      <Styled.TableHead className="mt-3">
        <div
          className="d-flex gap-2 align-items-center"
          style={{ fontWeight: 500 }}
        >
          <TextStyles.FontSize14px
            className="d-flex justify-content-start text-start"
            style={{ width: "150%", minWidth: 30 }}
          >
            <ReactTooltip
              anchorId="description"
              content="Sort by description"
            />
            <span
              className="three-dots hover-purpose"
              id="description"
              role="button"
              onClick={() => setSortingActiveHandler("purpose")}
            >
              Description
              {selectedSortingOne === "purpose" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-purpose"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-purpose"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start text-start"
            style={{ width: "120%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="project" content="Sort by project" />
            <span
              className="three-dots hover-project"
              id="project"
              role="button"
              onClick={() => setSortingActiveHandler("project_name")}
            >
              Project
              {selectedSortingOne === "project_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-project"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-project"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start text-start"
            style={{ width: "110%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="client" content="Sort by client" />
            <span
              className="three-dots hover-company"
              id="client"
              role="button"
              onClick={() => setSortingActiveHandler("company_name")}
            >
              Client
              {selectedSortingOne === "company_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-company"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-company"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start text-start"
            style={{ width: "90%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="date" content="Sort by date" />
            <span
              className="three-dots hover-date"
              id="date"
              role="button"
              onClick={() => setSortingActiveHandler("paid_date")}
            >
              Paid date
              {selectedSortingOne === "paid_date" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-date"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-date"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-center text-center"
            style={{ width: "80%", minWidth: 30 }}
          >
            <span className="three-dots">Status</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-center text-center"
            style={{ width: "80%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="amount" content="Sort by amount" />
            <span
              className="three-dots hover-amount"
              id="amount"
              role="button"
              onClick={() =>
                setSortingActiveHandler("total_amount_in_user_currency")
              }
            >
              Amount
              {selectedSortingOne === "total_amount_in_user_currency" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-amount"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-amount"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="transaction-actions"
            style={{
              width: "80%",
            }}
          />
        </div>
      </Styled.TableHead>
      {transaction && transaction.length > 0 ? (
        transaction.map((data, index) => {
          return (
            <Styled.TableCell
              key={index}
              style={{
                borderRadius:
                  transaction.length - 1 === index ? "0 0 12px 12px" : "",
              }}
              onClick={() => {
                if (data.transaction_type === "expense") {
                  setShowEditExpenseModal(true);
                  setSelectedExpenseData(data);
                } else {
                  viewIncomeRelatedInvoiceHandler(data);
                }
              }}
            >
              <div className="d-flex gap-2 align-items-center w-100">
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{
                    width: "150%",
                    textAlign: "start",
                    alignItems: "center",
                    minWidth: 30,
                  }}
                >
                  <NorthSharpIcon
                    sx={{
                      color:
                        data.transaction_type === "income"
                          ? Colors.success
                          : Colors.red,
                      fontSize: 22,
                      transform:
                        data.transaction_type === "income"
                          ? ""
                          : "rotate(-180deg)",
                    }}
                  />

                  <span className="three-dots">{data.purpose}</span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "120%", textAlign: "start", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {data.project_id && data.project_id.project_name}
                  </span>
                </TextStyles.FontSize14px>

                <TextStyles.GreyFont14px
                  className="d-flex justify-content-start"
                  style={{ width: "110%", textAlign: "start", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {data.client_id && data.client_id.company_name}
                  </span>
                </TextStyles.GreyFont14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "90%", textAlign: "start", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {data.paid_date &&
                      moment(data.paid_date).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>

                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "start",
                    minWidth: 30,
                  }}
                >
                  <TextStyles.FontSize14px
                    className="three-dots"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.2rem",
                      fontWeight: 600,
                      boxSizing: "border-box",
                      padding: "0.25rem 0.5rem",
                      maxHeight: "1.5rem",
                      background:
                        data.transaction_type === "income"
                          ? "#DDF8EC"
                          : "#ffeded",

                      color:
                        data.transaction_type === "income"
                          ? "#188652"
                          : "#f74040",
                    }}
                  >
                    <span className="three-dots">{data.transaction_type}</span>
                  </TextStyles.FontSize14px>
                </div>

                <TextStyles.FontSize14px
                  className="d-flex flex-column justify-content-center align-items-center text-center"
                  style={{ width: "80%", minWidth: 30 }}
                >
                  <div className="three-dots">
                    <span className="three-dots">
                      {currencySymbol}
                      {data.total_amount_in_user_currency
                        ? parseFloat(
                            data.total_amount_in_user_currency
                          ).toFixed(2)
                        : "00.00"}
                    </span>
                  </div>

                  {currencySymbol !== data.currency.symbol && (
                    <div style={{ color: Colors.font2 }} className="three-dots">
                      <span className="three-dots">
                        {`${data.currency ? data.currency.symbol : "$"}`}
                        {data.total_money
                          ? parseFloat(data.total_money).toFixed(2)
                          : "00.00"}
                      </span>
                    </div>
                  )}
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="transaction-actions"
                  style={{
                    width: "80%",
                  }}
                >
                  <div
                    className="dropdown"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <MoreHorizIcon
                        fontSize="large"
                        sx={{
                          background: Colors.white,
                          cursor: "pointer",
                          borderRadius: "6px",
                          padding: "2px 4px",
                          height: "30px",
                          color: "grey",
                        }}
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {data.transaction_type === "expense" && (
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowEditExpenseModal(true);
                              setSelectedExpenseData(data);
                            }}
                          >
                            <TextStyles.FontSize14px>
                              Edit
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}

                      <li>
                        <button
                          className="dropdown-item"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShow(true);
                            setSelectedExpenseData(data);
                            setConfirmDialog({
                              onConfirm: () => deleteTransactionHandler(data),
                            });
                          }}
                        >
                          <TextStyles.FontSize14px>
                            Delete
                          </TextStyles.FontSize14px>
                        </button>
                      </li>
                      {data.transaction_type === "income" && (
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={(e) => {
                              e.stopPropagation();
                              viewIncomeRelatedInvoiceHandler(data);
                            }}
                          >
                            <TextStyles.FontSize14px>
                              View related invoice
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                </TextStyles.FontSize14px>
              </div>
            </Styled.TableCell>
          );
        })
      ) : isTrLoading && (transaction === null || transaction.length === 0) ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        !isTrLoading &&
        (transaction === null || transaction.length === 0) && (
          <Styled.ListBox>
            <img src={NoTransactionsImage} alt="no-transactions-img" />
            <TextStyles.GreyFont18px className="mt-2">
              There are no transactions yet.
            </TextStyles.GreyFont18px>
          </Styled.ListBox>
        )
      )}
      <div className="d-flex justify-content-center py-4">
        {trTotalCount > itemsPerPage && (
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(trTotalCount / itemsPerPage)}
            page={page}
          />
        )}
      </div>
      {show && (
        <DeleteModal
          title={
            selectedExpenseData &&
            selectedExpenseData.transaction_type === "income"
              ? "income"
              : "expense"
          }
          confirmDialog={confirmDialog}
          showDeleteModal={show}
          handleCloseDeleteModal={handleClose}
        />
      )}

      {showNewExpenseModal && (
        <NewExpense
          show={showNewExpenseModal}
          handleClose={handleCloseNewExpenseModal}
          setUpdateRefresh={setUpdateRefresh}
          updateRefresh={updateRefresh}
          selectedClient={c_data_position}
        />
      )}
      {showEditExpenseModal && (
        <EditExpense
          show={showEditExpenseModal}
          handleClose={handleCloseEditExpenseModal}
          selectedExpenseData={selectedExpenseData}
          setUpdateRefresh={setUpdateRefresh}
          updateRefresh={updateRefresh}
        />
      )}
    </div>
  );
}
