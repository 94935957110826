import React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

import { makeStyles } from "@mui/styles";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput}`,
    height: "2.75rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400 !important",
    borderRadius: "6px !important",
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    background: `${Colors.white} !important`,
    boxShadow: "none !important",
    padding: "0px 6px",

    "&:hover": {
      border: `1px solid ${Colors.primary}`,
    },

    "&.Mui-focused": {
      border: `1px solid ${Colors.primary}`,
    },
  },

  input: {
    "& input::placeholder": {
      fontFamily: "Poppins",
      padding: "none",
      fontSize: "0.875rem",
      color: Colors.font1,
    },
  },
});

export default function SearchComponent({
  placeholder,
  defaultValue,
  onChange,
}) {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <MenuIcon sx={{ fontSize: 24, color: Colors.primary }} />
      <InputBase
        className={classes.input}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder || "Search user"}
        inputProps={{ "aria-label": "Search user" }}
        value={defaultValue ? defaultValue : ""}
        onChange={onChange}
      />
      <button>
        <SearchIcon sx={{ fontSize: 24, color: Colors.primary }} />
      </button>
    </Paper>
  );
}
