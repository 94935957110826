import React from "react";
import { PieChart, Pie, Cell } from "recharts";
// import Colors from "../../../../Constants/Colors";

const COLORS = ["#5b336f", "#a770c3", "#c382e3"];

const RADIAN = Math.PI / 180;

// Function to render customized labels
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      fontSize={12}
    >
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

const UserTypesGraph = ({ googleUsers, emailUsers, linkedinUsers }) => {
  const data = [
    { name: "Google", value: googleUsers },
    { name: "Email", value: emailUsers },
    { name: "LinkedIn", value: linkedinUsers },
  ];

  return (
    <PieChart width={250} height={250}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={90}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default UserTypesGraph;
