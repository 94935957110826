import React, { useEffect, useState, useMemo } from "react";

import { useForm, Controller, useFieldArray } from "react-hook-form";
import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

import { Popover } from "antd";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import NoTimeTrackingImage from "../../../../../Assets/assetsnew/no-time-tracking.svg";

import TextStyles from "../../../../../Constants/TextStyles";
import { useDispatch, useSelector } from "react-redux";

import { message } from "antd";
import Styled from "../../../TimeTracking/Reports/ReportsStyles";
import {
  update_single_timer_activity_name,
  update_single_timer_date,
  update_single_timer_invoice,
  delete_single_timer_activity,
  createActivityList,
  fetchAllActivityLists,
  fetchActivityReportsForGraph,
} from "../../../../../store/service/timeTrackingService";
import {
  convertSecondsToHHMMSS,
  convertSecondsToHHMM,
} from "../../../Commons/utils";

import { AutoCompleteCreateActivityList } from "../../../Commons/TimeTrackingAutoComplete/Autocompletelist2";
import moment from "moment/moment";

import SingleActivityEditModal from "../../../TimeTracking/TimerModals/SingleActivityEditModal";
import DeleteModal from "../../../Commons/DeleteModal";

import SelectActivity from "../../../Commons/SelectActivity";

import TTInvoicedStatus from "../../../Commons/TTInvoicedStatus";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import PieChartGraph from "../../../TimeTracking/Reports/PieChartGraph";
import Colors from "../../../../../Constants/Colors";
import ReportsCSV from "../../../TimeTracking/Reports/ReportsCSV";
import { Bar, BarChart, LabelList, Tooltip, XAxis, YAxis } from "recharts";
import Pagination from "../../../Commons/Pagination";
import { ReactTooltip } from "../../../Commons/ReactTooltip";
import { timeTrackingActions } from "../../../../../store/storage/timetrackingSlice";
import SelectDate from "../../../Commons/SelectDate";
import LoaderSpin from "../../../Commons/LoaderSpin";
import { postRequest } from "../../../../../axios/axios";
import CustomDatePickerThree from "../../../Commons/DatePickers/CustomDatePickerThree";
import CustomTimePickerTwo from "../../../Commons/DatePickers/CustomTimePickerTwo";

const Reports = () => {
  const dispatch = useDispatch();
  const reportsDataForGraph = useSelector(
    (state) => state.timeTracking.reportsDataForGraph
  );

  const selectedProject = useSelector((state) => state.project.selectedProject);

  const isLoading = useSelector((state) => state.timeTracking.isLoading);
  const activityListData = useSelector(
    (state) => state.timeTracking.activityListData
  );

  const toggleFilter = useSelector(
    (state) => state.timeTracking.toggleReportsFilterInProject
  );

  const filteredData = useSelector(
    (state) => state.timeTracking.filteredReportsDataInProject
  );

  const filteredActivity =
    filteredData && filteredData.filteredActivity
      ? filteredData.filteredActivity
      : null;
  const filteredInvoiced =
    filteredData && filteredData.filteredInvoiced
      ? filteredData.filteredInvoiced
      : null;
  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;

  const sortingData = useSelector(
    (state) => state.timeTracking.sortingReportsDataInProject
  );

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "start_time";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  const [isReportLoading, setIsReportLoading] = useState(false);
  const [reportsData, setReportsData] = useState(null);
  const [totalTtReportCount, setTotalTtReportCount] = useState(null);

  const [singleActivitySelectedData, setSingleActivitySelectedData] =
    useState(null);
  const [singleActivityEditModal, setSingleActivityEditModal] = useState(false);
  const handleCloseSingleActivityEditModal = () =>
    setSingleActivityEditModal(false);

  const [singleConfirmDialog, setSingleConfirmDialog] = useState(false);
  const [singleDeleteModal, setSingleDeleteModal] = useState(false);
  const handleCloseSingleDeleteModal = () => setSingleDeleteModal(false);

  const [updateRefresh, setUpdateRefresh] = useState(false);

  const toggleShowFilter = () => {
    dispatch(timeTrackingActions.toggleReportsActionInProject());
  };

  // sorting clients
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      timeTrackingActions.sortingReportsActionInProject({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      timeTrackingActions.sortingReportsActionInProject({
        selectedSortingTwo: event.target.value,
      })
    );
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const defaultReportsData = useMemo(() => {
    const data =
      reportsData &&
      reportsData.map((element) => {
        const _id = element._id;
        const start_time = element.start_time && new Date(element.start_time);
        const timesInSecs = element.timesInSecs;
        const activity_name =
          element.activity_name &&
          activityListData &&
          activityListData
            .filter((d) => d.activity_name === element.activity_name)
            .map((data) => {
              return {
                _id: data._id,
                title: data.activity_name,
              };
            })[0];
        const is_invoiced = element.is_invoiced;
        const project_id = element.project_id
          ? {
              _id: element.project_id._id,
              title: element.project_id.project_name,
              client_id: element.client_id && element.client_id._id,
              client_name: element.client_id && element.client_id.company_name,
            }
          : null;
        const timer_status = element.timer_status;
        return {
          _id,
          start_time,
          start_date: start_time,
          timesInSecs,
          activity_name,
          is_invoiced,
          project_id,
          timer_status,
        };
      });
    return data;
  }, [reportsData]);

  useEffect(() => {
    reset({
      update_activity: defaultReportsData,
    });
  }, [reportsData]);

  const { control, reset } = useForm({
    defaultValues: {
      update_activity: defaultReportsData,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "update_activity",
  });

  useEffect(() => {
    setPage(1);
  }, [filteredData, itemsPerPage]);

  useEffect(() => {
    if (reportsData && reportsData.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [reportsData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  const fetchReportHandler = async (data) => {
    const token = localStorage.getItem("token");
    setIsReportLoading(true);
    postRequest("tt/fetch_activity_reports", token, data)
      .then((data) => {
        if (data.data.success) {
          setIsReportLoading(false);
          setReportsData(data.data.data.data);
          setTotalTtReportCount(data.data.data.totalTtCount);
        }
      })
      .catch((err) => {
        setIsReportLoading(false);
        if (err && err.response && err.response.data) {
          return message.error(`${err.response.data.message}`);
        }
        if (err.isAxiosError) {
          return message.error(`${err.message}`);
        }
      });
  };

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).format("YYYY-MM-DD");
      end_time = moment(filterdDateRange[0]).format("YYYY-MM-DD");
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(6, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(14, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(29, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(44, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(59, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(89, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "This month") {
        const startDateOfThisMonth = moment(new Date())
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDateOfThisMonth = moment(new Date())
          .endOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last month") {
        const endDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        const startDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "This year") {
        const endDateOfThisYear = moment(new Date())
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfThisYear = moment(new Date())
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisYear).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last year") {
        const endDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastYear).format("YYYY-MM-DD");
      }
    }

    const Obj = {
      start_time,
      end_time,
      client_id:
        selectedProject && selectedProject.client_id
          ? selectedProject.client_id._id
          : null,
      project_id: selectedProject ? selectedProject._id : null,

      activity_name: filteredActivity !== 0 ? filteredActivity : null,
      is_invoiced:
        filteredInvoiced === "Invoiced"
          ? true
          : filteredInvoiced === "Not invoiced"
          ? false
          : null,
    };
    fetchReportHandler({
      ...Obj,
      page: page,
      perPage: itemsPerPage,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    });
    dispatch(fetchActivityReportsForGraph(Obj));
  }, [updateRefresh, filteredData, page, itemsPerPage, sortingData]);

  const createActivityHandler = (data) => {
    if (data && !data._id) {
      let Obj = {
        activity_name: data.inputValue || data,
      };
      dispatch(timeTrackingActions.addNewActivityList(Obj));
      dispatch(createActivityList(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists());
        }
      });
    }
  };

  const updateSingleActivityName = (value, data) => {
    const Obj = {
      activity_name:
        value && value.inputValue
          ? value.inputValue
          : value && value.title
          ? value.title
          : value
          ? value
          : null,
      _id: data._id,
    };

    dispatch(update_single_timer_activity_name(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const updateSingleActivityDate = (newDate, data) => {
    const startDate = data && data.start_date;
    const start_time = new Date(
      moment(newDate).set({
        hour: moment(startDate).get("hour"),
        minute: moment(startDate).get("minute"),
        second: moment(startDate).get("second"),
      })
    );
    const Obj = {
      _id: data._id,
      start_time,
    };
    dispatch(update_single_timer_date(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const updateSingleActivityTime = (newDate, tData) => {
    const start_time = new Date(newDate);
    const Obj = {
      _id: tData._id,
      start_time,
    };
    dispatch(update_single_timer_date(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const singleActivityInvoicedHandler = (element) => {
    const Obj = {
      _id: element._id,
      is_invoiced: !element.is_invoiced,
    };
    dispatch(update_single_timer_invoice(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const deleteSingleActivityHandler = (data) => {
    const Obj = {
      _id: data._id,
    };
    dispatch(delete_single_timer_activity(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleCloseSingleDeleteModal();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const totalTimes = useMemo(() => {
    let sum = 0;
    reportsDataForGraph &&
      reportsDataForGraph.forEach((d) => {
        if (d.timesInSecs) sum += d.timesInSecs;
      });
    return sum;
  }, [reportsDataForGraph]);

  const totalTimesNotInvoiced = useMemo(() => {
    let sum = 0;
    const data =
      reportsDataForGraph &&
      reportsDataForGraph.filter((data) => data.is_invoiced === false);
    data &&
      data.forEach((d) => {
        if (d.timesInSecs) sum += d.timesInSecs;
      });

    return sum;
  }, [reportsDataForGraph]);

  const totalTimesInvoiced = useMemo(() => {
    let sum = 0;
    const data =
      reportsDataForGraph &&
      reportsDataForGraph.filter((data) => data.is_invoiced === true);
    data &&
      data.forEach((d) => {
        if (d.timesInSecs) sum += d.timesInSecs;
      });

    return sum;
  }, [reportsDataForGraph]);

  const invoicedPercentage = Math.round(
    (totalTimesInvoiced / totalTimes) * 100
  );
  const notInvoicedPercentage = Math.round(
    (totalTimesNotInvoiced / totalTimes) * 100
  );

  const totalTimeInhoursInEachDay = (days) => {
    let sum = 0;
    const data =
      reportsDataForGraph &&
      reportsDataForGraph.filter(
        (data) => moment(new Date(data.start_time)).format("ddd") === days
      );
    data &&
      data.forEach((d) => {
        if (d.timesInSecs) sum += d.timesInSecs;
      });
    return sum;
  };

  const daysData = [
    {
      days: "Sunday",
      hours: totalTimeInhoursInEachDay("Sun"),
    },
    {
      days: "Monday",
      hours: totalTimeInhoursInEachDay("Mon"),
    },
    {
      days: "Tuesday",
      hours: totalTimeInhoursInEachDay("Tue"),
    },
    {
      days: "Wednesday",
      hours: totalTimeInhoursInEachDay("Wed"),
    },
    {
      days: "Thursday",
      hours: totalTimeInhoursInEachDay("Thu"),
    },
    {
      days: "Friday",
      hours: totalTimeInhoursInEachDay("Fri"),
    },
    {
      days: "Saturday",
      hours: totalTimeInhoursInEachDay("Sat"),
    },
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          style={{
            margin: "40px 20px 0 20px",
            padding: "10px 15px 10px 15px",
            background: Colors.white,
            borderRadius: 6,
            border: "1px solid #b6bac7",
          }}
        >
          <TextStyles.FontSize14px className="text-center">
            {label && `${label}`}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize18px
            className="text-center"
            style={{ color: Colors.primary }}
          >
            {payload &&
              payload[0] &&
              `${convertSecondsToHHMM(payload[0].value).formattedTime}`}
          </TextStyles.FontSize18px>
        </div>
      );
    }
    return null;
  };

  const CustomizedLabel = (props) => {
    const { x, width, value } = props;
    return (
      <text
        style={{ fontSize: 12, fontFamily: "Poppins" }}
        x={x + width / 2}
        y={width}
        fill={Colors.font2}
        textAnchor="middle"
        position="top"
      >
        {value.split("")[0]}
      </text>
    );
  };

  const BarChartGraph = () => {
    return (
      <BarChart
        data={daysData}
        width={300}
        height={120}
        margin={{
          top: 30,
          bottom: 5,
        }}
        barSize={18}
      >
        <XAxis dataKey="days" hide="true" />
        <YAxis hide="true" />
        <Tooltip content={CustomTooltip} />
        <Bar
          dataKey="hours"
          fill={Colors.primary}
          background={{ fill: Colors.backgroundPink }}
          minPointSize={0}
        >
          <LabelList dataKey="days" content={<CustomizedLabel />} />
        </Bar>
      </BarChart>
    );
  };

  const mappedDataForCsv = useMemo(() => {
    const returnData = reportsDataForGraph
      ? reportsDataForGraph.map((data) => {
          let timeInSeconds = data.timesInSecs;
          let timeInHours = Math.abs(timeInSeconds / 3600).toFixed(2);
          let start_time = moment(new Date(data.start_time)).format("h:mm a");
          let end_time = moment(new Date(data.start_time))
            .add(timeInSeconds, "seconds")
            .format("h:mm a");
          let activity = data.activity_name;
          let client = data.client_id && data.client_id.company_name;
          let project = data.project_id && data.project_id.project_name;
          let date = moment(new Date(data.start_time)).format("DD/MM/YYYY");
          let invoiced = data.is_invoiced;
          return {
            Activity: activity,
            Date: date,
            Start: start_time,
            End: end_time,
            Hours: timeInHours,
            Client: client,
            Project: project,
            Invoiced: invoiced,
          };
        })
      : [];
    return returnData;
  }, [reportsDataForGraph]);

  const sumOfTotalTime = useMemo(() => {
    const calculatedSum =
      mappedDataForCsv &&
      mappedDataForCsv.reduce((acc, curr) => acc + parseFloat(curr.Hours), 0);
    return calculatedSum;
  }, [mappedDataForCsv]);

  const csvData = [
    ...mappedDataForCsv,
    // Row with sum
    {},
    {
      End: "Total Hours",
      Hours: sumOfTotalTime,
    },
  ];

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="company_name">
            <FolderSharedTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2"></span>Client name
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="company_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_name">
            <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2"></span>Project name
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_name"}
              value="project_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="start_time">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2"></span>Date
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="start_time"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "start_time"}
              value="start_time"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="activity_name">
            <AccessTimeIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2"></span>Activity name
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="activity_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "activity_name"}
              value="activity_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2"></span>Ascending (A-Z)
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2"></span>Descending (Z-A)
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  const setSortingActiveHandler = (selectedSort) => {
    dispatch(
      timeTrackingActions.sortingReportsActionInProject({
        selectedSortingOne: selectedSort,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center my-3">
        <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
          Filter
        </TextStyles.HoverEffectSpan>

        <TextStyles.GreyFont20px className="mx-2">|</TextStyles.GreyFont20px>

        <TextStyles.FontSize16px className="me-2">
          <Popover
            placement="bottomRight"
            // title={"Hello"}
            content={sortingContent}
            trigger="click"
            open={openSorting}
            onOpenChange={handleOpenSortingChange}
          >
            <TextStyles.HoverEffectSpan sort={true}>
              Sort
              <ArrowDropDownOutlinedIcon
                sx={{
                  color: Colors.font2,
                  fontSize: 27,
                  transform: openSorting ? "rotate(180deg)" : "",
                }}
              />
            </TextStyles.HoverEffectSpan>
          </Popover>
        </TextStyles.FontSize16px>
        <ReportsCSV data={csvData} />
      </div>

      {toggleFilter && (
        <div className="d-flex flex-wrap gap-3 justify-content-start mb-3">
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>
            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                dispatch(
                  timeTrackingActions.filterReportsActionInProject({
                    filterdDateRange: value,
                  })
                );
              }}
            />
          </div>
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Activity
            </TextStyles.FontSize14px>
            <SelectActivity
              defaultValue={filteredActivity && filteredActivity}
              options={activityListData}
              onChange={(e) =>
                dispatch(
                  timeTrackingActions.filterReportsActionInProject({
                    filteredActivity: e.target.value,
                  })
                )
              }
            />
          </div>
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Invoice Status
            </TextStyles.FontSize14px>
            <TTInvoicedStatus
              defaultValue={filteredInvoiced && filteredInvoiced}
              onChange={(e) =>
                dispatch(
                  timeTrackingActions.filterReportsActionInProject({
                    filteredInvoiced: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
      )}

      <Styled.GraphRectangle className="d-flex flex-wrap gap-3 justify-content-evenly align-items-center mb-4">
        <div className="d-flex flex-column">
          <TextStyles.GreyFont20px
            style={{ fontWeight: 500, color: Colors.primary }}
          >
            {convertSecondsToHHMM(totalTimes).formattedTime}
          </TextStyles.GreyFont20px>
          <TextStyles.GreyFont14px
            style={{ fontSize: 12, fontWeight: 500 }}
          >{`${
            convertSecondsToHHMM(totalTimesNotInvoiced).formattedTime
          } not invoiced`}</TextStyles.GreyFont14px>
        </div>
        <div className="d-flex flex-column">
          <BarChartGraph />
          <TextStyles.GreyFont14px
            className="text-center"
            style={{ fontSize: 13 }}
          >
            Activity by day
          </TextStyles.GreyFont14px>
        </div>
        <div className="d-flex">
          <PieChartGraph
            invoiced={invoicedPercentage}
            not_invoiced={notInvoicedPercentage}
          />
        </div>
      </Styled.GraphRectangle>
      <Styled.TableHead>
        <TextStyles.FontSize14px
          className="d-flex gap-3 align-items-center w-100"
          style={{ fontWeight: 500 }}
        >
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ maxWidth: 75, minWidth: 20 }}
          >
            Hours
          </div>
          <div
            className="d-none d-md-flex align-items-center justify-content-center w-100"
            style={{ maxWidth: 70, minWidth: 20 }}
          >
            <span className="three-dots">Time</span>
          </div>
          <div
            className="d-none d-md-flex align-items-center justify-content-center w-100"
            style={{ maxWidth: 100, minWidth: 20 }}
          >
            <ReactTooltip anchorId="date" content="Sort by date" />
            <span
              className="three-dots hover-start-time"
              id="date"
              role="button"
              onClick={() => setSortingActiveHandler("start_time")}
            >
              Date
              {selectedSortingOne === "start_time" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-start-time"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-start-time"
                    />
                  )}
                </>
              )}
            </span>
          </div>

          <div
            className="d-flex align-items-center justify-content-start w-100"
            style={{ minWidth: 100, maxWidth: 600 }}
          >
            <ReactTooltip anchorId="activity" content="Sort by activity" />
            <span
              className="three-dots hover-activity"
              id="activity"
              role="button"
              onClick={() => setSortingActiveHandler("activity_name")}
            >
              Activity
              {selectedSortingOne === "activity_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-activity"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-activity"
                    />
                  )}
                </>
              )}
            </span>
          </div>
          <TextStyles.FontSize14px
            className="d-flex gap-2 justify-content-end"
            style={{
              width: 100,
              flexGrow: 1,
            }}
          >
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ maxWidth: 70, minWidth: 30 }}
            >
              <span className="three-dots">Invoiced</span>
            </div>
            <div
              className="d-flex justify-content-end w-100"
              style={{ maxWidth: 30, minWidth: 30 }}
            />
          </TextStyles.FontSize14px>
        </TextStyles.FontSize14px>
      </Styled.TableHead>
      {fields && fields.length > 0 ? (
        fields.map((item, index) => {
          const sum = item.timesInSecs;
          return (
            <Styled.TableCell
              style={{
                marginBottom: fields.length - 1 !== index ? 2 : "",
                borderRadius:
                  fields.length - 1 === index ? "0 0 12px 12px" : "",
              }}
              key={index}
            >
              <TextStyles.FontSize14px className="d-flex gap-3 align-items-center w-100">
                <div
                  className="d-flex justify-content-center align-items-center w-100"
                  style={{ maxWidth: 75, minWidth: 20 }}
                >
                  <span className="three-dots">
                    {convertSecondsToHHMMSS(sum).formattedTime}
                  </span>
                </div>
                <div
                  className="d-none d-md-flex align-items-center justify-content-center w-100"
                  style={{ maxWidth: 70, minWidth: 20 }}
                >
                  <Controller
                    control={control}
                    name={`update_activity.${index}.start_time`}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomTimePickerTwo
                          onChange={(value) => {
                            onChange(value);
                            updateSingleActivityTime(value, item);
                          }}
                          selected={value}
                          placeholder="Start time"
                          disabled={isLoading ? true : false}
                        />
                      );
                    }}
                  />
                </div>
                <div
                  className="d-none d-md-flex align-items-center justify-content-center w-100"
                  style={{ maxWidth: 105, minWidth: 20 }}
                >
                  <Controller
                    control={control}
                    name={`update_activity.${index}.start_date`}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomDatePickerThree
                          onChange={(value) => {
                            onChange(value);
                            updateSingleActivityDate(value, item);
                          }}
                          selected={value}
                          placeholder="Start date"
                          disabled={isLoading ? true : false}
                        />
                      );
                    }}
                  />
                </div>

                <div
                  className="d-flex align-items-center justify-content-start w-100"
                  style={{ minWidth: 100, maxWidth: 600, flexGrow: 1 }}
                >
                  <Controller
                    control={control}
                    name={`update_activity.${index}.activity_name`}
                    render={({ field: { onChange, value } }) => (
                      <AutoCompleteCreateActivityList
                        placeholder="Write activity..."
                        options={
                          activityListData &&
                          activityListData.map((d) => {
                            return { _id: d._id, title: d.activity_name };
                          })
                        }
                        onChange={(value) => {
                          onChange(value);
                          createActivityHandler(value);
                          updateSingleActivityName(value, item);
                        }}
                        defaultValue={value}
                        disabled={isLoading ? true : false}
                      />
                    )}
                  />
                </div>

                <TextStyles.FontSize14px
                  className="d-flex gap-2 justify-content-end"
                  style={{
                    width: 100,
                    flexGrow: 1,
                  }}
                >
                  <div
                    className="d-flex justify-content-center align-items-center w-100"
                    style={{ maxWidth: 70, minWidth: 30 }}
                  >
                    <FiberManualRecordIcon
                      id={`edit_invoice${index}`}
                      sx={{
                        fontSize: 15,
                        cursor: "pointer",
                        color:
                          item.is_invoiced === true
                            ? Colors.primary
                            : "#c3c3c6",
                        background: Colors.white,
                        padding: "2px 6px",
                        borderRadius: "6px",
                        width: 30,
                        height: 30,
                      }}
                      onClick={() => singleActivityInvoicedHandler(item)}
                    />
                    <ReactTooltip
                      anchorId={`edit_invoice${index}`}
                      content={item.is_invoiced ? "Invoiced" : "Not invoiced"}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 30, minWidth: 30 }}
                  >
                    {" "}
                    <div className="dropdown">
                      <div data-bs-toggle="dropdown" aria-expanded="false">
                        <MoreHorizIcon
                          fontSize="large"
                          sx={{
                            background: Colors.white,
                            cursor: "pointer",
                            borderRadius: "6px",
                            padding: "2px 4px",
                            height: "30px",
                            color: "grey",
                          }}
                        />
                      </div>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => {
                              setSingleActivityEditModal(true);
                              setSingleActivitySelectedData(item);
                            }}
                          >
                            <TextStyles.FontSize14px>
                              Edit
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => {
                              setSingleDeleteModal(true);
                              setSingleConfirmDialog({
                                onConfirm: () =>
                                  deleteSingleActivityHandler(item),
                              });
                            }}
                          >
                            <TextStyles.FontSize14px>
                              Delete
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </TextStyles.FontSize14px>
              </TextStyles.FontSize14px>
            </Styled.TableCell>
          );
        })
      ) : isReportLoading &&
        (reportsData === null || reportsData.length === 0) ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        !isReportLoading &&
        (reportsData === null || reportsData.length === 0) && (
          <Styled.ListBox>
            <img src={NoTimeTrackingImage} alt="no-time-tracting-img" />
            <TextStyles.GreyFont18px className="mt-2">
              There is no activity yet.
            </TextStyles.GreyFont18px>
          </Styled.ListBox>
        )
      )}
      <div className="d-flex justify-content-center py-4">
        {totalTtReportCount > itemsPerPage && (
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(totalTtReportCount / itemsPerPage)}
            page={page}
          />
        )}
      </div>
      {singleActivityEditModal && (
        <SingleActivityEditModal
          updateRefresh={updateRefresh}
          setUpdateRefresh={setUpdateRefresh}
          show={singleActivityEditModal}
          handleClose={handleCloseSingleActivityEditModal}
          selectedData={singleActivitySelectedData}
        />
      )}

      <DeleteModal
        title="activity"
        confirmDialog={singleConfirmDialog}
        showDeleteModal={singleDeleteModal}
        handleCloseDeleteModal={handleCloseSingleDeleteModal}
      />
    </div>
  );
};

export default React.memo(Reports);
