import React from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import Colors from "../../../Constants/Colors";

export const ReactTooltip = ({
  anchorId,
  content,
  place,
  variant,
  borderRadius,
  padding,
  color,
  fontSize,
  fontWeight,
}) => {
  return (
    <Tooltip
      style={{
        padding: padding || 10,
        borderRadius: borderRadius || 6,
        fontSize: fontSize || "14px",
        color: color || Colors.font1,
        fontWeight: fontWeight || 400,
        fontFamily: "Poppins",
        background:
          variant === "grey"
            ? "#edeef2"
            : variant === "green"
            ? "green"
            : variant === "brandColor"
            ? Colors.hoverButtonColor
            : variant === "backgroundPink"
            ? Colors.backgroundPink
            : "",
      }}
      anchorId={anchorId}
      place={place || "top"}
      content={content}
      variant={variant || "light"}
    />
  );
};

export const ReactTooltipWithIcon = ({
  anchorId,
  content,
  place,
  variant,
  borderRadius,
  padding,
  color,
  Icon,
}) => {
  return (
    <Tooltip
      style={{
        padding: padding || 10,
        borderRadius: borderRadius || 6,
        fontSize: "14px",
        color: color || Colors.font1,
        fontWeight: 400,
        fontFamily: "Poppins",
        background:
          variant === "grey" ? "#edeef2" : variant === "green" ? "green" : "",
      }}
      anchorId={anchorId}
      place={place || "top"}
      variant={variant || "light"}
    >
      {Icon ? (
        <>
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 22 }} />{" "}
          <span className="ms-1">{content}</span>
        </>
      ) : (
        <>
          <BorderColorOutlinedIcon sx={{ color: Colors.font2, fontSize: 22 }} />
          <span className="ms-1">{content}</span>
        </>
      )}
    </Tooltip>
  );
};
