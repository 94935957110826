import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: "none",
    borderRadius: `${sizes.formInputBorderRadius} !important`,
    fontSize: "0.875rem !important",
    height: "2.5rem",
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "&:hover": {
      border: "none",
    },

    "&.Mui-focused": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: Colors.font2,
    fontWeight: "500 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const newlyUsersOptions = [
  {
    value: "Today",
    label: "Today",
  },
  {
    value: "Yesterday",
    label: "Yesterday",
  },
  {
    value: "Last 2 Days",
    label: "Last 2 Days",
  },
  {
    value: "Last 3 Days",
    label: "Last 3 Days",
  },
  {
    value: "This Week",
    label: "This Week",
  },
  {
    value: "Last Week",
    label: "Last Week",
  },
  {
    value: "This Month",
    label: "This Month",
  },
  {
    value: "Last Month",
    label: "Last Month",
  },
];

export default function FilterNewlyUsers(props) {
  const classes = useStyles();

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        defaultValue={
          props.defaultValue ? props.defaultValue : newlyUsersOptions[0].value
        }
        id="grouped-select"
        sx={{ width: "100%" }}
        onChange={props.onChange}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 250 } },
          // disableScrollLock: true,
        }}
      >
        {newlyUsersOptions &&
          newlyUsersOptions.map((data, index) => (
            <MenuItem
              key={index}
              value={data.value}
              className={classes.listMenu}
            >
              {data.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
