import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { message } from "antd";

import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import EventIcon from "@mui/icons-material/Event";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import PaidTwoToneIcon from "@mui/icons-material/PaidTwoTone";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

import { Popover } from "antd";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NoInvoiceImage from "../../../../../Assets/assetsnew/no-invoice.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { useDispatch, useSelector } from "react-redux";
import {
  // fetchInvoices,
  deleteInvoice,
  paidInvoice,
} from "../../../../../store/service/invoiceService";
import { invoiceActions } from "../../../../../store/storage/invoiceSlice";

import DeleteModal from "../../../Commons/DeleteModal";

import Colors from "../../../../../Constants/Colors";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "../../../invoices/InvoiceStyles";
import SelectProjects from "../../../Commons/SelectProjects";
import InvoiceStatus from "../../../Commons/InvoiceStatus";
import Pagination from "../../../Commons/Pagination";
import { ReactTooltip } from "../../../Commons/ReactTooltip";
import SelectDate from "../../../Commons/SelectDate";
import LoaderSpin from "../../../Commons/LoaderSpin";
import { postRequest } from "../../../../../axios/axios";
// import URL from "../../../../../axios/constant";

export default function Invocies() {
  const history = useHistory();
  const dispatch = useDispatch();
  let urlElements = window.location.href.split("/");
  const id = urlElements[5];

  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [invoiceTotalCount, setInvoiceTotalCount] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  // const invoiceData = useSelector((state) => state.invoices.invoiceData);
  // const isLoading = useSelector((state) => state.invoices.isLoading);

  // const invoiceTotalCount = useSelector(
  //   (state) => state.invoices.invoiceTotalCount
  // );

  const c_data_position = useSelector((state) => state.clients.c_data_position);

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );

  const toggleFilter = useSelector(
    (state) => state.invoices.toggleFilterInClient
  );

  const filteredData = useSelector(
    (state) => state.invoices.filteredDataInClient
  );

  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;
  const filteredProject =
    filteredData && filteredData.filteredProject
      ? filteredData.filteredProject
      : null;

  const filteredInvoicedStatus =
    filteredData && filteredData.filteredInvoicedStatus
      ? filteredData.filteredInvoicedStatus
      : null;

  const sortingData = useSelector(
    (state) => state.invoices.sortingDataInClient
  );

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "issued";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  const [updateRefresh, setUpdateRefresh] = useState(false);

  const toggleShowFilter = () => {
    dispatch(invoiceActions.toggleActionInClient());
  };

  // sorting invoice
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      invoiceActions.sortingActionInClient({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      invoiceActions.sortingActionInClient({
        selectedSortingTwo: event.target.value,
      })
    );
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setPage(1);
  }, [
    filteredData,
    itemsPerPage,
    // updateRefresh,
  ]);

  useEffect(() => {
    if (invoiceData && invoiceData.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [invoiceData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  const fetchInvoiceHandler = async (data) => {
    const token = localStorage.getItem("token");
    setIsInvoiceLoading(true);
    postRequest("invoices/fetch_invoices", token, data)
      .then((data) => {
        if (data.data.success) {
          setIsInvoiceLoading(false);
          setInvoiceData(data.data.data.data);
          setInvoiceTotalCount(data.data.data.invoiceTotalCount);
        }
      })
      .catch((err) => {
        setIsInvoiceLoading(false);
        if (err && err.response && err.response.data) {
          return message.error(`${err.response.data.message}`);
        }
        if (err.isAxiosError) {
          return message.error(`${err.message}`);
        }
      });
  };

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).format("YYYY-MM-DD");
      end_time = moment(filterdDateRange[0]).format("YYYY-MM-DD");
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(6, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(14, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(29, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(44, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(59, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(89, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "This month") {
        const startDateOfThisMonth = moment(new Date())
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDateOfThisMonth = moment(new Date())
          .endOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last month") {
        const endDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        const startDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "This year") {
        const endDateOfThisYear = moment(new Date())
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfThisYear = moment(new Date())
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisYear).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last year") {
        const endDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastYear).format("YYYY-MM-DD");
      }
    }

    const Obj = {
      page: page,
      perPage: itemsPerPage,
      start_time,
      end_time,
      client_id: c_data_position && c_data_position._id,
      project_id: filteredProject !== 0 ? filteredProject : null,
      status:
        filteredInvoicedStatus !== "All statuses"
          ? filteredInvoicedStatus
          : null,
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    fetchInvoiceHandler(Obj);
    // dispatch(fetchInvoices(Obj));
  }, [updateRefresh, filteredData, page, itemsPerPage, sortingData]);

  const deleteInvoiceHandler = async (_id) => {
    dispatch(deleteInvoice({ _id })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseDeleteModal();
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const createInvoiceHandler = () => {
    const client_id =
      clientOptionData &&
      clientOptionData.filter((client) => client._id === id)[0];
    const Obj = {
      client_id,
    };
    dispatch(invoiceActions.createInvoiceModeAction("quickInvoice"));
    dispatch(invoiceActions.selectedInvoiceAction(Obj));
    history.push(`/db/clients/${id}/invoices/create/quickInvoice`);
  };

  const editInvoiceHandler = (item) => {
    dispatch(invoiceActions.editInvoice(item));
    history.push(`/db/clients/${id}/invoices/${item._id}/review`);
  };

  const viewInvoiceHandler = (item) => {
    history.push(`/db/invoices/view/${item._id}/details`);
  };

  const recordPaymentHandler = (_id) => {
    dispatch(paidInvoice({ _id })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseDeleteModal();
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="invoice_no">
            <ReceiptIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Invoice number</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="invoice_no"
              type="radio"
              name="sorting"
              value="invoice_no"
              checked={selectedSortingOne === "invoice_no"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="company_name">
            <FolderSharedTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Client name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="company_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="project_name">
            <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Project name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_name"}
              value="project_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="due_date">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Due date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="due_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "due_date"}
              value="due_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="issued">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Issued date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="issued"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "issued"}
              value="issued"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="invoice_status">
            <AdjustOutlinedIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Status</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="invoice_status"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "status"}
              value="status"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="invoiced_amount">
            <PaidTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Amount</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="invoiced_amount"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "invoiced_amount"}
              value="invoiced_amount"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  const setSortingActiveHandler = (selectedSort) => {
    dispatch(
      invoiceActions.sortingActionInClient({
        selectedSortingOne: selectedSort,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-3 justify-content-sm-between align-items-center my-3">
        <TextStyles.FontSize20px className="d-flex justify-content-start">
          Invoices
        </TextStyles.FontSize20px>

        <div className="d-flex justify-content-end align-items-center ms-auto">
          <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
            Filter
          </TextStyles.HoverEffectSpan>
          <TextStyles.GreyFont20px className="mx-2">|</TextStyles.GreyFont20px>

          <TextStyles.FontSize16px className="me-2">
            <Popover
              placement="bottomRight"
              // title={"Hello"}
              content={sortingContent}
              trigger="click"
              open={openSorting}
              onOpenChange={handleOpenSortingChange}
            >
              <TextStyles.HoverEffectSpan sort={true}>
                Sort
                <ArrowDropDownOutlinedIcon
                  sx={{
                    color: Colors.font2,
                    fontSize: 27,
                    transform: openSorting ? "rotate(180deg)" : "",
                  }}
                />
              </TextStyles.HoverEffectSpan>
            </Popover>
          </TextStyles.FontSize16px>

          <Styled.Oval onClick={createInvoiceHandler}>
            <AddOutlinedIcon sx={{ fontSize: 20, color: Colors.white }} />
          </Styled.Oval>
        </div>
      </div>
      {toggleFilter && (
        <div className="d-flex flex-wrap justify-content-start mb-3">
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>

            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                dispatch(
                  invoiceActions.filterActionInClient({
                    filterdDateRange: value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Project
            </TextStyles.FontSize14px>
            <SelectProjects
              defaultValue={filteredProject && filteredProject}
              options={projectOptionsData}
              onChange={(e) =>
                dispatch(
                  invoiceActions.filterActionInClient({
                    filteredProject: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Status
            </TextStyles.FontSize14px>
            <InvoiceStatus
              defaultValue={filteredInvoicedStatus && filteredInvoicedStatus}
              onChange={(e) =>
                dispatch(
                  invoiceActions.filterActionInClient({
                    filteredInvoicedStatus: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
      )}
      <Styled.TableHead className="mt-3">
        <div
          className="d-flex gap-2 align-items-center"
          style={{ fontWeight: 500 }}
        >
          <TextStyles.FontSize14px
            className="d-flex justify-content-start text-start"
            style={{ width: "60%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="invoice" content="Sort by invoice" />
            <span
              className="three-dots hover-invoice"
              id="invoice"
              role="button"
              onClick={() => setSortingActiveHandler("invoice_no")}
            >
              Invoice
              {selectedSortingOne === "invoice_no" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-invoice"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-invoice"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "150%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="project" content="Sort by project" />
            <span
              className="three-dots hover-project"
              id="project"
              role="button"
              onClick={() => setSortingActiveHandler("project_name")}
            >
              Project
              {selectedSortingOne === "project_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-project"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-project"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "150%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="client" content="Sort by client" />
            <span
              className="three-dots hover-company"
              id="client"
              role="button"
              onClick={() => setSortingActiveHandler("company_name")}
            >
              Client
              {selectedSortingOne === "company_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-company"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-company"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "100%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="issuedDate" content="Sort by issued date" />
            <span
              className="three-dots hover-issued-date"
              id="issuedDate"
              role="button"
              onClick={() => setSortingActiveHandler("issued")}
            >
              Issued
              {selectedSortingOne === "issued" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-issued-date"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-issued-date"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "100%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="dueDate" content="Sort by due date" />
            <span
              className="three-dots hover-due-date"
              id="dueDate"
              role="button"
              onClick={() => setSortingActiveHandler("due_date")}
            >
              Due date
              {selectedSortingOne === "due_date" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-due-date"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-due-date"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "80%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="status" content="Sort by status" />
            <span
              className="three-dots hover-status"
              id="status"
              role="button"
              onClick={() => setSortingActiveHandler("status")}
            >
              Status
              {selectedSortingOne === "status" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-status"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-status"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-start"
            style={{ width: "80%", minWidth: 30 }}
          >
            <ReactTooltip anchorId="amount" content="Sort by amount" />
            <span
              className="three-dots hover-amount"
              id="amount"
              role="button"
              onClick={() => setSortingActiveHandler("invoiced_amount")}
            >
              Amount
              {selectedSortingOne === "invoiced_amount" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-amount"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-amount"
                    />
                  )}
                </>
              )}
            </span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex justify-content-end"
            style={{
              width: "80%",
              maxWidth: 35,
              minWidth: 35,
            }}
          />
        </div>
      </Styled.TableHead>
      {invoiceData && invoiceData.length > 0 ? (
        invoiceData.map((item, index) => {
          return (
            <Styled.TableCell
              key={index}
              style={{
                borderRadius:
                  invoiceData.length - 1 === index ? "0 0 12px 12px" : "",
              }}
              onClick={() => {
                if (item.status === "Paid") {
                  viewInvoiceHandler(item);
                } else {
                  editInvoiceHandler(item);
                }
              }}
            >
              <div className="d-flex gap-2 align-items-center w-100">
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start text-start"
                  style={{ width: "60%", minWidth: 30 }}
                >
                  <span className="three-dots">{item && item.invoice_no}</span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "150%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {(item &&
                      item.project_id &&
                      item.project_id.project_name) ||
                      "-"}
                  </span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "150%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {(item && item.client_id && item.client_id.company_name) ||
                      "-"}
                  </span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "100%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item &&
                      item.issued &&
                      moment(item && item.issued).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "100%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item && item.due_date
                      ? moment(item && item.due_date).format("DD MMM YYYY")
                      : moment(item && item.issued).format("DD MMM YYYY")}
                  </span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{
                    width: "80%",
                    textAlign: "start",
                    minWidth: 30,
                  }}
                >
                  <TextStyles.FontSize14px
                    className="three-dots"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.2rem",
                      fontWeight: 600,
                      boxSizing: "border-box",
                      padding: "0.25rem 0.5rem",
                      maxHeight: "1.5rem",
                      background:
                        (item.due_date || item.issued) &&
                        new Date(
                          moment(item.due_date || item.issued).format(
                            "YYYY MM DD"
                          )
                        ) >=
                          new Date(moment(new Date()).format("YYYY MM DD")) &&
                        item.status === "Sent"
                          ? "#f2ecfb"
                          : (item.due_date || item.issued) &&
                            new Date(
                              moment(item.due_date || item.issued).format(
                                "YYYY MM DD"
                              )
                            ) <
                              new Date(
                                moment(new Date()).format("YYYY MM DD")
                              ) &&
                            item.status === "Sent"
                          ? "#ffeded"
                          : item.status === "Draft"
                          ? "#f1f1f1"
                          : item.status === "Paid"
                          ? "#DDF8EC"
                          : "",

                      color:
                        new Date(
                          moment(item.due_date || item.issued).format(
                            "YYYY MM DD"
                          )
                        ) >=
                          new Date(moment(new Date()).format("YYYY MM DD")) &&
                        item.status === "Sent"
                          ? Colors.primary
                          : new Date(
                              moment(item.due_date || item.issued).format(
                                "YYYY MM DD"
                              )
                            ) <
                              new Date(
                                moment(new Date()).format("YYYY MM DD")
                              ) && item.status === "Sent"
                          ? "#f74040"
                          : item.status === "Draft"
                          ? Colors.font1
                          : item.status === "Paid"
                          ? "#188652"
                          : "",
                    }}
                  >
                    <span className="three-dots">
                      {new Date(
                        moment(item.due_date || item.issued).format(
                          "YYYY MM DD"
                        )
                      ) < new Date(moment(new Date()).format("YYYY MM DD")) &&
                      item.status === "Sent"
                        ? "due"
                        : new Date(
                            moment(item.due_date || item.issued).format(
                              "YYYY MM DD"
                            )
                          ) >=
                            new Date(moment(new Date()).format("YYYY MM DD")) &&
                          item.status === "Sent"
                        ? "Sent"
                        : item.status}
                    </span>
                  </TextStyles.FontSize14px>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-start"
                  style={{ width: "80%", minWidth: 30 }}
                >
                  <span className="three-dots">
                    {item.currency && item.currency.symbol}
                    {item.invoiced_amount > 0
                      ? parseFloat(item.invoiced_amount).toFixed(2)
                      : "00.00"}
                  </span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-end"
                  style={{
                    width: "80%",
                    maxWidth: 35,
                    minWidth: 35,
                  }}
                >
                  <div
                    className="dropdown"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div data-bs-toggle="dropdown" aria-expanded="false">
                      <MoreHorizIcon
                        fontSize="large"
                        sx={{
                          background: Colors.white,
                          cursor: "pointer",
                          borderRadius: "6px",
                          padding: "2px 4px",
                          height: "30px",
                          color: "grey",
                        }}
                      />
                    </div>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {item.status !== "Draft" && (
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={(e) => {
                              e.stopPropagation();
                              viewInvoiceHandler(item);
                            }}
                          >
                            <TextStyles.FontSize14px>
                              View
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}

                      {item.status === "Sent" && (
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                recordPaymentHandler(item._id);
                              }}
                            >
                              Record full payment
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}
                      {item.status !== "Paid" && (
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={(e) => {
                              e.stopPropagation();
                              editInvoiceHandler(item);
                            }}
                          >
                            <TextStyles.FontSize14px>
                              Edit
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      )}
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDeleteModal(true);
                            setConfirmDialog({
                              onConfirm: () => deleteInvoiceHandler(item._id),
                            });
                          }}
                        >
                          <TextStyles.FontSize14px>
                            Delete
                          </TextStyles.FontSize14px>
                        </button>
                      </li>
                    </ul>
                  </div>
                </TextStyles.FontSize14px>
              </div>
            </Styled.TableCell>
          );
        })
      ) : isInvoiceLoading &&
        (invoiceData === null || invoiceData.length === 0) ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        !isInvoiceLoading &&
        (invoiceData === null || invoiceData.length === 0) && (
          <Styled.ListBox>
            <img src={NoInvoiceImage} alt="no-invoice-img" />
            <TextStyles.GreyFont18px className="mt-2">
              There are no invoices yet.
            </TextStyles.GreyFont18px>
          </Styled.ListBox>
        )
      )}
      <div className="d-flex justify-content-center py-4">
        {invoiceTotalCount > itemsPerPage && (
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(invoiceTotalCount / itemsPerPage)}
            page={page}
          />
        )}
      </div>

      <DeleteModal
        title="invoice"
        confirmDialog={confirmDialog}
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
    </div>
  );
}
