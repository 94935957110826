import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Modal } from "react-bootstrap";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import moment from "moment";

const MoreUsersInfo = ({ show, handleClose, selectedData }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      // dialogClassName="ToMakeModalWidth600px"
      centered
      scrollable
    >
      <Modal.Body className="py-4 px-3 px-sm-5">
        <div style={{ position: "absolute", top: 10, right: 10 }}>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </div>
        <div className="d-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-start align-items-center gap-3 gap-sm-5 mb-2">
          <div>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                background: Colors.primary,
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                cursor: "pointer",
                fontWeight: 600,
                fontSize: 64,
                color: Colors.white,
              }}
            >
              {selectedData && `${selectedData.name[0].toUpperCase()}`}
            </div>
          </div>
          <div className="font-weight-500">
            <div className="d-flex mb-2">
              <TextStyles.FontSize14px>Name:</TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData && selectedData.name}
              </TextStyles.FontSize14px>
            </div>
            <div className="d-flex mb-2">
              <TextStyles.FontSize14px>Email:</TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData && selectedData.email}
              </TextStyles.FontSize14px>
            </div>
            <div className="d-flex mb-2">
              <TextStyles.FontSize14px>CreatedAt:</TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData &&
                  selectedData.createdAt &&
                  moment(selectedData.createdAt).format("DD MMM YYYY")}
              </TextStyles.FontSize14px>
            </div>
            <div className="d-flex mb-2 mb-sm-0">
              <TextStyles.FontSize14px>Last Login:</TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData &&
                  selectedData.lastLogin &&
                  moment(selectedData.lastLogin).format("DD MMM YYYY")}
              </TextStyles.FontSize14px>
            </div>
            <div className="d-flex d-sm-none my-sm-0">
              <TextStyles.FontSize14px style={{ color: Colors.primary }}>
                User Currency:
              </TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData &&
                  selectedData.userCurrency &&
                  `${selectedData.userCurrency.name}(${selectedData.userCurrency.code})`}
              </TextStyles.FontSize14px>
            </div>
          </div>
        </div>
        <div className="d-none d-sm-flex mt-2 font-weight-500">
          <TextStyles.FontSize14px style={{ color: Colors.primary }}>
            User Currency:
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="ms-2" style={{ opacity: 0.8 }}>
            {selectedData &&
              selectedData.userCurrency &&
              `${selectedData.userCurrency.name}(${selectedData.userCurrency.code})`}
          </TextStyles.FontSize14px>
        </div>
        <div
          className="my-2"
          style={{
            width: "100%",
            height: 1,
            background: Colors.primary,
            opacity: 0.2,
          }}
        />
        <div className="d-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between align-items-center gap-2 gap-sm-5 mb-2">
          <div className="font-weight-500">
            <div className="d-flex">
              <TextStyles.FontSize14px>Clients:</TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData && selectedData.totalClients}
              </TextStyles.FontSize14px>
            </div>
          </div>
          <div className="font-weight-500">
            <div className="d-flex">
              <TextStyles.FontSize14px>Agreements:</TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData && selectedData.totalAgreements}
              </TextStyles.FontSize14px>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between align-items-center gap-2 gap-sm-5 mb-2">
          <div className="font-weight-500">
            <div className="d-flex">
              <TextStyles.FontSize14px>Projects:</TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData && selectedData.totalProjects}
              </TextStyles.FontSize14px>
            </div>
          </div>
          <div className="font-weight-500">
            <div className="d-flex">
              <TextStyles.FontSize14px>Transactions:</TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData && selectedData.totalTransactions}
              </TextStyles.FontSize14px>
            </div>
          </div>
        </div>{" "}
        <div className="d-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between align-items-center gap-2 gap-sm-5 mb-2">
          <div className="font-weight-500">
            <div className="d-flex">
              <TextStyles.FontSize14px>Invoices:</TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData && selectedData.totalInvoices}
              </TextStyles.FontSize14px>
            </div>
          </div>
          <div className="font-weight-500">
            <div className="d-flex">
              <TextStyles.FontSize14px>
                Total Activities:
              </TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData && selectedData.totalActivities}
              </TextStyles.FontSize14px>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between align-items-center gap-2 gap-sm-5">
          <div className="font-weight-500">
            <div className="d-flex">
              <TextStyles.FontSize14px>Tasks:</TextStyles.FontSize14px>
              <TextStyles.FontSize14px
                className="ms-2"
                style={{ opacity: 0.8 }}
              >
                {selectedData && selectedData.totalTasks}
              </TextStyles.FontSize14px>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MoreUsersInfo;
