import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    fontWeight: "400 !important",
    borderRadius: "6px !important",
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    height: "2.75rem !important",
    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: Colors.font1,
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const userTypeOptions = [
  { label: "All users", value: "All users" },
  { label: "Email", value: "email" },
  { label: "Google", value: "google" },
  { label: "LinkedIn", value: "linkedin" },
];

export default function SelectUserType(props) {
  const classes = useStyles();

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        value={props.defaultValue ? props.defaultValue : "All users"}
        id="grouped-select"
        label="Grouping"
        onChange={props.onChange}
        sx={{ width: 150 }}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 250 } },
        }}
      >
        {userTypeOptions &&
          userTypeOptions.map((data, index) => (
            <MenuItem
              key={index}
              value={data.value}
              className={classes.listMenu}
            >
              {data.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
